import React,{useEffect, useMemo, useState} from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../../src/pages/Dashboard/LeadsCol"
//Import Breadcrumb
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom";

const ProjectStatus = props => {
    const {status} = useParams();
  const [datatorepresent ,setDatafor] = useState([])
  const [count ,setCount] = useState(1)
  const columns = useMemo(
    () => [
     
      {
        Header: "Business Name",
        accessor: "business_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Client Name",
        accessor: "client_name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },

      {
        Header: "Status",
        disableFilters: true,
        accessor: "sale_status",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Client Signed Date",
        disableFilters: true,
        accessor: "client_signed_date",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Customer Signed Date",
        disableFilters: true,
        accessor: "customer_signed_date",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Tech Signed Date",
        disableFilters: true,
        accessor: "tech_signed_date",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "action_id",
        Cell: cellProps => {
          
          return (
            <div>

            <Link
              type=""
              color="primary"
              className="btn-sm"
              style={{background:'green',color:'white',paddingTop:5,paddingLeft:10,paddingRight:10,paddingBottom:5,borderRadius:10}}
              title={`View`}
              to={{pathname:`/viewProject/${cellProps.value}`}}
            >
            View
            </Link>
            &nbsp;
            <Link
              to={{pathname:`/projectRemarks/${cellProps.value}`}}
              style={{color:'white',paddingTop:5,paddingLeft:10,paddingRight:10,paddingBottom:5,borderRadius:10}}
              color="danger"
              className="btn-sm bg-info"
              title={`Remarks`}
              
            >
             Remarks
            </Link>
            </div>
          )
        },
      },
     
    ],
    []
  )

const getValue = (param)=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch(`https://crmapi.frontforcecrm.com/project/projectStatus?status=${param}`, requestOptions)
    .then(response => response.json())
    .then(result => setDatafor(result))
    .catch(error => console.log('error', error));
}
  document.title = `Projects ${status} | Front Force CRM`
  useEffect(()=>{
    getValue(status);
  },[status])
  return (
    <>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid={true}>
          <Breadcrumbs title="Project Management" breadcrumbItem="All Projects" />
          <Col xl={12}>
            <Card>
              <CardBody>
             
                <Col lg="12">
                  <TableContainer
                    columns={columns}
                    data={datatorepresent}
                    isGlobalFilter={true}
                    customPageSize={20}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default ProjectStatus
