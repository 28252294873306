import React, { useEffect, useState } from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  CardHeader,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import LineBar from "../../../pages/AllCharts/echart/linebarchart"

import userimage from "../../../assets/images/brands/github.png"
import { Link } from "react-router-dom"
import {BarChartOther} from "pages/AllCharts/chartjs/barchart"




const Project_Dashboard = props => {
  const [allProject, setAllProject] = useState(0)
  const [activeProject, setActiveProject] = useState(0)
  const [completeProject, setCompleteProject] = useState(0)
  const [cstAssigned, setCstAssign] = useState(0)
  const [projectIssue, setProjectIssue] = useState(0)
  const [UnAssigned, setUnassigned] = useState(0)
  const [count, setCount] = useState(1)
  const [role, setUserRole] = useState(null)
  const [currentId, setCurrentID] = useState(null)
  const [techTeamReport, setTechTeamReport] = useState([])
  const getTechReport = async ()=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    await fetch("https://crmapi.frontforcecrm.com/project-dashboard/tech", requestOptions)
      .then(response => response.json())
      .then(result =>{
        setTechTeamReport(result.users)
      })
      .catch(error => console.log('error', error));
  }
  const getDataFrom = async () =>{
    let local = await JSON.parse(localStorage.getItem('authUser'));
    if(local == null){
        window.location.href ="/login";
        return;
    }else{
      setCurrentID(local.id)
      setUserRole (local.role)
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("method", "non-filter");
    urlencoded.append("id", local.id);
    urlencoded.append("role", local.role);
    urlencoded.append("date1", "");
    urlencoded.append("date2", "");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("https://crmapi.frontforcecrm.com/project-dashboard", requestOptions)
      .then(response => response.json())
      .then(result => {
         setActiveProject(result.activeProject)
         setAllProject(result.totalProject)
         setCompleteProject(result.completeProject)
         setProjectIssue(result.issueProject)
         setUnassigned(result.unassignedeProject)
         setCstAssign(result.cstProject)
      })
      .catch(error => console.log('error', error));
    }
    
  }
  useEffect(()=>{
      getDataFrom();
      getTechReport();
  },[count])
  const reports = [
    { title: "All Projects",
     iconClass: "bx-support",
      description: allProject,
      link:'./allprojects' 
    },
    {
      title: "New (Un-Assigned)",
      iconClass: "bx-phone-call",
      description: UnAssigned,
      link:'/projects/un-assigned'
    },
    {
      title: "New (Assigned)",
      iconClass: "bx-phone-call",
      description: cstAssigned,
      link:'/projects/cst-assigned'
    },
    {
      title: "Active",
      iconClass: "bxs-user-plus",
      description: activeProject,
      link:'/projects/active'
    },
    { title: "Projects Issue", iconClass: "bxs-briefcase",link:'/projects/issue', description: projectIssue },
    {
      title: "Closed",
      iconClass: "bxs-user-plus",
      description: completeProject,
      link:'/projects/completed'
    },
  ]
  document.title = "Project Dashboard | Front Force CRM"
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="Leads Management" breadcrumbItem="All Leads" /> */}
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4"></CardTitle>
                <Form 
                onSubmit={(e)=>{
                  e.preventDefault();
                  var myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                  
                  var urlencoded = new URLSearchParams();
                  urlencoded.append("method", "filter");
                  urlencoded.append("id", currentId);
                  urlencoded.append("role", role);
                  urlencoded.append("date1", e.target.elements.first_date.value);
                  urlencoded.append("date2", e.target.elements.second_date.value);
                  
                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: 'follow'
                  };
                  
                  fetch("https://crmapi.frontforcecrm.com/project-dashboard", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                      setActiveProject(result.activeProject)
                      setAllProject(result.totalProject)
                      setCompleteProject(result.completeProject)
                      setProjectIssue(result.issueProject)
                      setUnassigned(result.unassignedeProject)
                      setCstAssign(result.cstProject)
                    })
                    .catch(error => console.log('error', error));
                  
                }}
                >
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          First Date
                        </Label>
                        <Input
                          className="form-control"
                          type="date"
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                          required
                          name="first_date"
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">Last Date</Label>
                        <Input
                          className="form-control"
                          type="date"
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                          required
                          name="second_date"
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <Label>{""}</Label>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          style={{ marginTop: 7 }}
                        >
                          Filter Data
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xl="12">
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
              {reports.map((report, key) => (
                <Col md="3" key={"_col_" + key}>
                  <Card
                    className="mini-stats-wid"
                    style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
                  >
                    <a href={report.link}>

                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{report.title}</p>
                          <h4 className="mb-0">{report.description}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                    </a>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
         <Col md={12}>
          <Card>
            <CardBody>
              <Row>

           {
            techTeamReport.map((e,index)=>{
                return(
                  <Col xl="4" sm="6" key={index}>
       
                  <Row>
                    <Col xl="5">
                      <div className="text-center p-4 border-end">
                        <div className="avatar-sm mx-auto mb-3 mt-1">
                          <span
                            className={
                              "avatar-title rounded-circle  " 
                            }
                          >
                            <img src= {`https://uss.frontforcecrm.com/uploads/${e.image}`} width={48} style={{borderRadius:50}}/>
                          </span>
                        </div>
                        <p className="bg-warning mb-2 badge text-wrap">{e.role}</p>
                        <h5 title={e.name} className="pb-1">{e.name}</h5>
                      </div>
                    </Col>
        
                    <Col xl="7">
                      <div className="p-4 text-center text-xl-start">
                        <Row>
                          <Col xs="6">
                            <div>
                              <p className=" mb-2 badge bg-info text-wrap" style={{color:'white'}}>Total</p>
                              <h5>{e.total}</h5>
                            </div>
                          </Col>
                          <Col xs="6">
                            <div>
                              <p className="mb-2 badge bg-primary text-wrap">Active</p>
                              <h5>{e.active}</h5>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div>
                              <p className="mb-2 badge bg-success text-wrap">
                                Complete
                              </p>
                              <h5>{e.complete}</h5>
                            </div>
                          </Col>
                        </Row>
                       
                      </div>
                    </Col>
                  </Row>
                
              </Col>
                );
            })
           }
              </Row>
            </CardBody>
          </Card>
         </Col>
         
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle>Tech Chart</CardTitle>
              </CardBody>
              <CardBody>
                <div id="mix-line-bar" className="e-chart">
                  <BarChartOther dataColors='["--bs-info","--bs-primary", "--bs-success"]' />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default Project_Dashboard
