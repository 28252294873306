import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import avatar from "../../../assets/images/users/avatar-1.jpg"

const EditProfile = () => {
  //meta title
  document.title = "Edit Profile | Front Force CRM"

  const [selectedFiles, setselectedFiles] = useState([])

  const options = [
    { value: "admin", label: "Admin" },
    { value: "supervisior", label: "Supervisior" },
    { value: "user", label: "User" },
    { value: "tech", label: "Tech" },
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Setting" breadcrumbItem="Edit Profile" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Change Image</CardTitle>

                  <Form>
                    <Row>
                      <Col sm="6">
                        <div className="ms-3">
                          <Label htmlFor="productname">Profile Image</Label>
                          <Input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="ms-3">
                          <Label htmlFor="productname"></Label>
                          <img
                            src={avatar}
                            alt=""
                            className="avatar-md rounded-circle"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">Full Name</Label>
                          <Input
                            id="fullname"
                            name="fullname"
                            type="text"
                            className="form-control"
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="userpassword">Password</Label>
                          <Input
                            id="userpassword"
                            name="userpassword"
                            type="password"
                            className="form-control"
                          />
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="useremail">Email Address</Label>
                          <Input
                            id="useremail"
                            name="useremail"
                            type="email"
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="retypepassword">
                            Retype Password
                          </Label>
                          <Input
                            id="retypepassword"
                            name="retypepassword"
                            type="password"
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-3">
                      <Button type="submit" color="primary" className="btn ">
                        Submit
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditProfile
