import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Createdispositions = () => {
  //meta title
  document.title = "Add Disposition | Front Force CRM"

  const [selectedFiles, setselectedFiles] = useState([])

  const options = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "In Active" },
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Disposition"
            breadcrumbItem="Create Disposition"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl={6}>
                      <CardTitle className="mb-4">Disposition Detail</CardTitle>
                    </Col>
                    <Col xl={6} className="text-end">
                      <Link to={"/dispositions"}>
                        <Button
                          type="button"
                          color="primary"
                          className=" btn-rounded mb-2 me-2"
                        >
                          All Dispositions
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Form
                  onSubmit={(e)=>{
                    e.preventDefault();
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                    var urlencoded = new URLSearchParams();
                    urlencoded.append("name", e.target.elements.name.value);
                    urlencoded.append("backgroundColor", e.target.elements.color.value);
                    urlencoded.append("shortcode", e.target.elements.short_code.value);

                    var requestOptions = {
                      method: 'POST',
                      headers: myHeaders,
                      body: urlencoded,
                      redirect: 'follow'
                    };

                    fetch("https://crmapi.frontforcecrm.com/dispositions/create", requestOptions)
                      .then(response => response.json())
                      .then(result => {
                        if(result.message ==='success'){
                          toastr.success('Project is successfully added');
                          e.target.elements.name.value="";
                          e.target.elements.color.value="";
                          e.target.elements.short_code.value=""
                        }else{
                          toastr.error('Error 500')
                        }
                      })
                      .catch(error => console.log('error', error));
                  }}  
                  >
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">Disposition Name</Label>
                          <Input
                            id="fullname"
                            name="name"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="short_code">
                            Disposition ShortCode
                          </Label>
                          <Input
                            id="short_code"
                            name="short_code"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <div className="mb-3">
                          <Label htmlFor="productname">Disposition Color</Label>
                          <Input
                            id="fullname"
                            name="color"
                            type="color"
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-3">
                      <Button type="submit" color="primary" className="btn ">
                        Submit
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Createdispositions
