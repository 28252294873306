import React, { useEffect, useMemo, useState } from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Modal,

} from "reactstrap"
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../../src/pages/Dashboard/LeadsCol"
//Import Breadcrumb
import classnames from "classnames"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import axios from "axios"
const ViewProject = props => {
  const [data, setDatafor] = useState([])
  const [transactionData, setTransactionData] = useState([])
  const [assignedUserData, setAssginedUserData] = useState([])
  const [projectTasks, setProjectTasks] = useState([])
  const { id } = useParams()
  const [count, setCount] = useState(1)
  const [modal_large, setmodal_large] = useState(false);
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [techList, setTechList] = useState([])
  const [userName, setUserName] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userid, setUserid] = useState(null);
  const [pendingStatus, setPendingStatus] = useState(null);
  const toggle = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const getValue = param => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch(
      `https://crmapi.frontforcecrm.com/project/singleProjectDetail?id=${param}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        result.rows.map((e)=>{
            if(e.sale_status === 'pending'){
                setPendingStatus('pending')
            }else{
                setPendingStatus('nothing')
            }
        })
        setDatafor(result.rows)
        setTransactionData(result.transactions)
        setAssginedUserData(result.assignedUsers)
        setProjectTasks(result.projectTasks)

        const activeTasks = result.projectTasks.filter(item => item.task_status === 'active');  
        const completedTasks = result.projectTasks.filter(item => item.task_status === 'completed');
        console.log(activeTasks.length)
        console.log(completedTasks.length)
        console.log(result.projectTasks.length)
      })
      .catch(error => console.log("error", error))
  }
  const getValueUser = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch(`https://crmapi.frontforcecrm.com/usersforassignment?only=tech`, requestOptions)
      .then(response => response.json())
      .then(result => setTechList(result))
      .catch(error => console.log("error", error))
  }
  document.title = "View Project | Front Force CRM"
  useEffect(() => {
    getValue(id)
    getValueUser()
    let local = JSON.parse(localStorage.getItem('authUser'));
    if(local == null){
        window.location.href = '/login';

    }else{
        setUserName(local.name)
        setUserRole(local.role)
        setUserid(local.id)
    }
  }, [count])
const StopProjectWork = () =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("sale_id", id);

        var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch("https://crmapi.frontforcecrm.com/project/stopWork", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message == 'success'){
                toastr.success('Project is stop successfully')
                getValue(id)
            }else{
                toastr.error('Error 500')
            }
        })
        .catch(error => console.log('error', error));
}
const StartProjectWork = () =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("sale_id", id);

        var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch("https://crmapi.frontforcecrm.com/project/startWork", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message == 'success'){
                toastr.success('Project is start successfully')
                getValue(id)
            }else{
                toastr.error('Error 500')
            }
        })
        .catch(error => console.log('error', error));
}
const AssignmnetProject = async() =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    let local = JSON.parse(localStorage.getItem('authUser'));
    var urlencoded = new URLSearchParams();
    urlencoded.append("sale_id", id);
    urlencoded.append("role", local.role);
    urlencoded.append("assigned_to", "94,126");
    urlencoded.append("currentUserId", local.id);

    var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
    };

    await fetch("https://crmapi.frontforcecrm.com/project/assignmentOfSale", requestOptions)
    .then(response => response.json())
    .then(result => {
        if(result.message === 'success'){
            toastr.success('Changes are successfully done');
        }else{
            toastr.error(`${result.message}`)
        }
    })
    .catch(error => console.log('error', error));
}
  return (
    <>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Project Management"
            breadcrumbItem="View Single Project"
          />
          <Col xl={12}>
            <Card>
              <CardBody>
                <Row>
                 
                  <Col md="12">
                    <Row>
                        <Col md={12}>
                        <Nav tabs >
                        <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                            active: verticalActiveTab === "1",
                            })}
                            onClick={() => {
                            toggle("1");
                            }}
                        >
                            Client Infomation
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                            active: verticalActiveTab === "2",
                            })}
                            onClick={() => {
                            toggle("2");
                            }}
                        >
                            Business Infomation
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                            active: verticalActiveTab === "3",
                            })}
                            onClick={() => {
                            toggle("3");
                            }}
                        >
                            Project Tasks
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                            active: verticalActiveTab === "4",
                            })}
                            onClick={() => {
                            toggle("4");
                            }}
                        >
                            Transaction History
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                            active: verticalActiveTab === "5",
                            })}
                            onClick={() => {
                            toggle("5");
                            }}
                        >
                           Services
                        </NavLink>
                        </NavItem>
                        
                        <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                            active: verticalActiveTab === "6",
                            })}
                            onClick={() => {
                            toggle("6");
                            }}
                        >
                           Project Assignment
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                            active: verticalActiveTab === "7",
                            })}
                            onClick={() => {
                            toggle("7");
                            }}
                        >
                           More Info
                        </NavLink>
                        </NavItem>
                    </Nav>
                        </Col>
                        <Col md={12} style={{textAlign:'end',paddingTop:20}}>
                            <button className="btn btn-sm btn-primary" onClick={()=>AssignmnetProject()}>Save Changes</button>&nbsp;&nbsp;
                            <a className="btn btn-sm btn-info" href={`../projectRemarks/${id}`}>Remarks</a>&nbsp;&nbsp;
                           {pendingStatus !== 'pending' ?  <button className="btn btn-sm btn-warning" onClick={()=>StopProjectWork()}>Stop Working</button>:<button className="btn btn-sm btn-success" onClick={()=>StartProjectWork()}>Start Working</button>}&nbsp;&nbsp;
                            <button className="btn btn-sm btn-danger">Delete</button>
                        </Col>
                    </Row>

                    <TabContent
                      activeTab={verticalActiveTab}
                      className="text-muted mt-4 mt-md-0"
                    >
                      {data.map((e, i) => (
                        <TabPane key={i} tabId="1">
                          <h4>Client Infomation</h4>
                          <hr />
                          <Row>
                            <Col md={12}>
                              <p>
                                Project Type: <strong>{e.business_type}</strong>
                              </p>
                            </Col>
                            <Col md={6}>
                              <p>
                                Full Name : <strong>{e.client_name}</strong>
                              </p>
                            </Col>
                            <Col md={6}>
                              <p>
                                Email :{" "}
                                <strong>
                                  {e.client_email !== ""
                                    ? e.client_email
                                    : "N/a"}
                                </strong>
                              </p>
                            </Col>
                            <Col md={6}>
                              <p>
                                Address : <strong>{e.client_address}</strong>
                              </p>
                            </Col>
                            <Col md={6}>
                              <p>
                                Client Signed date :{" "}
                                <strong>{e.client_signed_date}</strong>
                              </p>
                            </Col>
                            <Col md={12}>
                              <p>
                                Project Status: <strong>{e.sale_status}</strong>
                              </p>
                            </Col>
                          </Row>
                        </TabPane>
                      ))}
                      {data.map((e, i) => (
                        <TabPane key={i} tabId="2">
                          <h4>Business Infomation</h4>
                          <hr />
                          <Row>
                            <Col md={12}>
                              <p>
                                Business Name :{" "}
                                <strong>{e.business_name}</strong>
                              </p>
                            </Col>
                            <Col md={6}>
                              <p>
                                Business Phone :{" "}
                                <strong>{e.client_phone}</strong>
                              </p>
                            </Col>
                            <Col md={6}>
                              <p>
                                Client Budget : <strong>{e.sale_amount}</strong>
                              </p>
                            </Col>
                            <Col md={12}>
                              <p>
                                Business Niche : <br></br>
                                {e.business_niche}
                              </p>
                            </Col>
                            <Col md={12}>
                              <p>
                                Business Description : <br></br>
                                <p dangerouslySetInnerHTML={{ __html: e.business_description }}></p>
                              </p>
                            </Col>
                            <Col md={12}>
                              <p>
                                Description for Customer Support: <br></br>
                                
                                <p dangerouslySetInnerHTML={{ __html: e.description_for_support }}></p>
                              </p>
                            </Col>
                            <Col md={12}>
                              <p>
                                Description for Tech:<br></br>
                                <p dangerouslySetInnerHTML={{ __html: e.description_for_tech }}></p>
                              </p>
                            </Col>
                          </Row>
                        </TabPane>
                      ))}

                      <TabPane tabId="3">
                        <h6>Project Tasks</h6>
                        <hr />
                        <button  type="button"
                                    onClick={() => {
                                        tog_large();
                                    }}
                                    className="btn btn-primary "
                                    data-toggle="modal"
                                    data-target=".bs-example-modal-lg">Add Task</button>
                        <Row style={{ paddingTop: 10 }}>
                          <Col md={4}>
                            <div
                              style={{
                                padding: 10,
                                color: "white",
                                borderRadius: 5,
                              }}
                              className="bg-primary"
                            >
                              Total Tasks ({projectTasks.length})
                            </div>
                          </Col>
                          <Col md={4}>
                            <div
                              style={{
                                padding: 10,
                                color: "white",
                                borderRadius: 5,
                              }}
                              className="bg-info"
                            >
                              Active Tasks ({ projectTasks.filter(item => item.task_status === 'active').length})
                            </div>
                          </Col>
                          <Col md={4}>
                            <div
                              style={{
                                padding: 10,
                                color: "white",
                                borderRadius: 5,
                              }}
                              className="bg-success"
                            >
                              Complete Tasks ({  projectTasks.filter(item => item.task_status === 'completed').length})
                            </div>
                          </Col>
                          <Col md={12}>
                         <table className="table table-responsive">
                            
                              <thead>
                                <tr>
                                    <th>Task Title</th>
                                    <th>Assigned To</th>
                                    <th>Staus</th>
                                    <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                    projectTasks.filter(item => item.task_status == 'active').map((e,i)=>
                                        <tr key={i}>
                                            <td>
                                            {e.title}

                                            </td>
                                            <td>
                                            {e.user_name}

                                            </td>
                                            <td>
                                            {e.task_status}

                                            </td>
                                            <td>
                                            <i onClick={()=>EditTask(e.id)} className="fa fa-edit"></i> 
                                            <i  className="fa fa-trash"></i> 

                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            
                        </table>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="4">
                        <h6>Transaction History</h6>
                        <hr />
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Date & Time</th>
                              <th>Amount</th>
                              <th>Amount type</th>
                              <th>Status</th>
                              <th>Received By</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionData.map((e, i) => (
                              <tr key={i}>
                                <td>{e.created_at}</td>
                                <td>{e.amount}</td>
                                <td>{e.amount_type}</td>
                                <td>{e.status}</td>
                                <td>{e.user_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </TabPane>
                      {data.map((e, i) => (
                        <TabPane tabId="5" key={i}>
                          <h4>Services Signed For</h4>
                        
                          <hr />
                          <Row>
                            <Col md={12}>
                              <ul>
                                {" "}
                                {e.service_sell.split(",").map((e, i) => {
                                  return <li key={i}>{e}</li>
                                })}{" "}
                              </ul>
                            </Col>
                          </Row>
                        </TabPane>
                      ))}
                      <TabPane tabId="6">
                        <h4>Customer Support</h4>

                        <hr />
                        <Row>
                          {techList
                            .filter(
                              user => user.addonrole === "customer support"
                            )
                            .map((e, i) => {
                              let val = assignedUserData.some(
                                item2 => e.id === item2.id
                              )
                              if (val) {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                        defaultChecked={true}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                        {e.name}
                                      </label>
                                    </div>
                                  </Col>
                                )
                              } else {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                        {e.name}
                                      </label>
                                    </div>
                                  </Col>
                                )
                              }
                            })}
                        </Row>
                        <h4>Software Developer</h4>
                        <hr />
                        <Row>
                          {techList
                            .filter(
                              user => user.addonrole === "software developer"
                            )
                            .map((e, i) => (
                              <Col key={i} xl={3} sm={6}>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={e.id}
                                    name="tech_assign"
                                    id={`defaultCheck-${e.id}`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`defaultCheck-${e.id}`}
                                  >
                                                                             {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }

                                  </label>
                                </div>
                              </Col>
                            ))}
                        </Row>
                        <h4>Web Developer</h4>
                        <hr />
                        <Row>
                          {techList
                            .filter(user => user.addonrole === "web developer")
                            .map((e, i) => {
                              let val = assignedUserData.some(
                                item2 => e.id === item2.id
                              )
                              if (val) {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                        defaultChecked={true}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                                                                {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }

                                      </label>
                                    </div>
                                  </Col>
                                )
                              } else {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                                                                {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }

                                      </label>
                                    </div>
                                  </Col>
                                )
                              }
                            })}
                        </Row>
                        <h4>Designer</h4>
                        <hr />
                        <Row>
                          {techList
                            .filter(user => user.addonrole === "designer")
                            .map((e, i) => {
                              let val = assignedUserData.some(
                                item2 => e.id === item2.id
                              )
                              if (val) {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        defaultChecked={true}
                                        id={`defaultCheck-${e.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                                                               {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }

                                      </label>
                                    </div>
                                  </Col>
                                )
                              } else {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                                                                {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }

                                      </label>
                                    </div>
                                  </Col>
                                )
                              }
                            })}
                        </Row>
                        <h4>SEO</h4>
                        <hr />
                        <Row>
                          {techList
                            .filter(user => user.addonrole === "seo")
                            .map((e, i) => {
                              let val = assignedUserData.some(
                                item2 => e.id === item2.id
                              )
                              if (val) {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                        defaultChecked={true}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                        {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }
                                      </label>
                                    </div>
                                  </Col>
                                )
                              } else {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                         {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }
                                      </label>
                                    </div>
                                  </Col>
                                )
                              }
                            })}
                        </Row>
                        <h4>Content Writer</h4>
                        <hr />
                        <Row>
                          {techList
                            .filter(user => user.addonrole === "content writer")
                            .map((e, i) => {
                              let val = assignedUserData.some(
                                item2 => e.id === item2.id
                              )
                              if (val) {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                        defaultChecked={true}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                         {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }
                                      </label>
                                    </div>
                                  </Col>
                                )
                              } else {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                         {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }
                                      </label>
                                    </div>
                                  </Col>
                                )
                              }
                            })}
                        </Row>
                        <h4>SMM</h4>
                        <hr />
                        <Row>
                          {techList
                            .filter(user => user.addonrole === "smm")
                            .map((e, i) => {
                              let val = assignedUserData.some(
                                item2 => e.id === item2.id
                              )
                              if (val) {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                        defaultChecked={true}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                         {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }
                                      </label>
                                    </div>
                                  </Col>
                                )
                              } else {
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="tech_assign"
                                        id={`defaultCheck-${e.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                                                                 {e.name} { e.team_lead  === 'Yes' ? <span className=" mb-2 badge bg-info text-wrap">Team lead</span> : null }

                                      </label>
                                    </div>
                                  </Col>
                                )
                              }
                            })}
                        </Row>
                      </TabPane>
                      {
                        data.map((e,i)=>(

                      <TabPane key={i} tabId="7">
                        <p>
                          Sale By: <strong>{e.name}</strong>
                        </p>
                      </TabPane>
                        ))
                      }
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Modal
                      size="lg"
                      isOpen={modal_large}
                      toggle={() => {
                        tog_large();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myLargeModalLabel"
                        >
                         Add Task
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_large(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Form
                        onSubmit={(e)=>{
                            e.preventDefault();
                            let local = JSON.parse(localStorage.getItem('authUser'));
                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var formdata = new URLSearchParams();
                            formdata.append('project_id',id)
                            formdata.append('user_id_assigned_by',local.id)
                            formdata.append('user_id_assigned_to',e.target.elements.assign_to.value)
                            formdata.append('title',e.target.elements.taskTitle.value)
                            formdata.append('description',e.target.elements.description_for_Task.value)
                            formdata.append('task_status','active')
                            formdata.append('deadline_date',e.target.elements.deadline.value)
                            
                            // var myHeaders = new Headers();
                            // myHeaders.append("Content-Type", `multipart/form-data boundary:${formdata._boundary}`);
                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                              };
                              
                              fetch("https://crmapi.frontforcecrm.com/project/add-task", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    if(result.message == 'success'){
                                        toastr.success('Project remarks are successfully added')
                                        setmodal_large(!modal_large);
                                        getValue(id);
                                    }else{
                                        toastr.error(`${result.message}`)
                                    }  
                                })
                                .catch(error => console.log('error', error));
                            
                        
                             
                        }}
                        >

                         <Row>
                            <Col md={12}>
                                <label>Task Title</label>
                                <input name="taskTitle" type="text" className="form-control"/>
                            </Col>
                            <Col md={12}>
                                <label>Select User</label>
                                <select className="form-control" name="assign_to">
                                    <option value={'null'}>--SELECT USER--</option>
                                {assignedUserData
                            .map((e, i) => {
                                return <option key={i} value={e.id}>{e.name}</option>
                            })}
                                </select>
                            </Col>
                            <hr/>
                            <Col md={12}>
                                <label>DealLine Date</label>
                                <input name="deadline" type="date" className="form-control"/>
                            </Col>
                            <hr/>
                            <Col md={12}>
                                <label>Description</label>
                                <textarea name="description_for_Task" type="text" className="form-control"/>
                            </Col>
                          
                           
                            <hr/>
                            <Col md={12} style={{textAlign:'end'}}>
                                 &nbsp;&nbsp;
                                <button className="btn btn-info" type="submit">Submit</button>
                            </Col>
                         </Row>
                        </Form>
                       
                      </div>
                    </Modal>
        </Container>
      </div>
    </>
  )
}
export default ViewProject
