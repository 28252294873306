import React, { useEffect,useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { isEmpty } from "lodash";

import { Col, Container, Row,Card,CardBody,Table,CardTitle,Modal,ModalBody ,Form} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { getProjectDetail as onGetProjectDetail } from "store/projects/actions";
import ProjectDetail from "pages/Projects/ProjectOverview/projectDetail";
import TeamMembers from "pages/Projects/ProjectOverview/teamMembers";
import OverviewChart from "pages/Projects/ProjectOverview/overviewChart";
import { options, series } from "common/data/projects";
import AttachedFiles from "pages/Projects/ProjectOverview/attachedFiles";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useSelector, useDispatch } from "react-redux";
import Comments from "pages/Projects/ProjectOverview/comments";
import { useParams,Link } from "react-router-dom";
import axios from "axios";

const ProjectRemarks = props => {

  //meta title
  document.title = "Project Remarks | Front Force CRM";
  const [data, setDatafor] = useState([])
  const [transactionData, setTransactionData] = useState([])
  const [assignedUserData, setAssginedUserData] = useState([])
  const [projectTasks, setProjectTasks] = useState([])
  const [remarksData, setRemarksData] = useState([])
  const [modal_large, setmodal_large] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userid, setUserid] = useState(null);
  const { id } = useParams()
  const getValue = param => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch(
      `https://crmapi.frontforcecrm.com/project/singleProjectDetail?id=${param}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setDatafor(result.rows)
        setTransactionData(result.transactions)
        setAssginedUserData(result.assignedUsers)
        setProjectTasks(result.projectTasks)
      })
      .catch(error => console.log("error", error))
  }
const getProjectRemarks = async (param) =>{

     var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
     await fetch(`https://crmapi.frontforcecrm.com/project/projectRemarks?id=${param}`, requestOptions)
        .then(response => response.json())
        .then(result => setRemarksData(result))
        .catch(error => console.log('error', error));
}


  

  useEffect(() => {
    getValue(id)
    getProjectRemarks(id)
    let local = JSON.parse(localStorage.getItem('authUser'));
    if(local == null){
        window.location.href = '/login';

    }else{
        setUserName(local.name)
        setUserRole(local.role)
        setUserid(local.id)
    }
  }, [id]);
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Project Overview" />

         
            <>
              <Row>
                <Col lg="8">
                <Card>
                    <CardBody>
                        <div className="d-flex">
                        {/* <img  alt="" className="avatar-sm me-4" /> */}
            
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="text-truncate font-size-15">{data.map((e)=>e.business_name)}</h5>
                            
                        </div>
                        </div>

                        <h5 className="font-size-15 mt-4">Project Details :</h5>
                        <p className="text-muted" dangerouslySetInnerHTML={{__html:data.map((e)=>e.business_description)}}></p>

                        <div className="text-muted mt-4">
                     
                        </div>

                        <Row className="task-dates">
                        <Col sm="4" xs="6">
                            <div className="mt-4">
                            <h5 className="font-size-14">
                                <i className="bx bx-calendar me-1 text-primary" /> CST Assign Date
                            </h5>
                            <p className="text-muted">{data.map((e)=>e.customer_signed_date)}</p>
                            </div>
                        </Col>

                        <Col sm="4" xs="6">
                            <div className="mt-4">
                            <h5 className="font-size-14">
                                <i className="bx bx-calendar-check me-1 text-primary" /> Tech Signed
                                Date
                            </h5>
                            <p className="text-muted">{data.map((e)=>e.tech_signed_date)}</p>
                            </div>
                        </Col>
                        <Col md={12} style={{paddingTop:15}}>
                            <Row>
                                <Col md={3}>

                                <button
                                    type="button"
                                    onClick={() => {
                                        tog_large();
                                    }}
                                    className="btn btn-primary "
                                    data-toggle="modal"
                                    data-target=".bs-example-modal-lg"
                                    >
                                    Add Remarks
                                    </button>
                                </Col>
                                <Col md={6}>

                            {/* <button className="btn btn-info">
                                New Assigner
                            </button> */}
                                </Col>
                            </Row>
                        </Col>
                        </Row>
                    </CardBody>
                    </Card>
                </Col>

                <Col lg="4">
                <Card>
      <CardBody>
        <CardTitle className="mb-4">Team Members</CardTitle>
        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            {assignedUserData.map((item, key) => (
              <tbody key={key}>
                <tr>

                  <td style={{ width: "50px" }}>
                    {item.image ?
                      <img src={`https://uss.frontforcecrm.com/uploads/${item.image}`} className="rounded-circle avatar-xs" alt="" />
                      :
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                          {item.addonrole}
                        </span>
                      </div>
                    }
                  </td>
                  <td><h5 className="font-size-14 m-0"><Link to="#" className="text-dark">{item.name}</Link></h5></td>
                  <td>
                    <div>
                      <Link to="#" className="badge bg-primary bg-soft text-primary font-size-11">{item.addonrole}</Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      </CardBody>
    </Card>
                </Col>
                <Col lg="12">
            <Card>
                <CardBody>
                    {
                        remarksData.map((e,i)=>(
                            <div className="d-flex mb-4" key={i}>
                           
                        <div className="flex-grow-1">
                            <div className="d-flex mt-3">
                                <div className="avatar-xs me-3">
                                    <img
                                    className="media-object rounded-circle avatar-xs"
                                    alt=""
                                       src={`https://uss.frontforcecrm.com/uploads/${e.image}`}
                                    />
                                </div>
                                <div className="flex-grow-1">
                                <h5 className="font-size-13 mb-1">{e.user_name} <code>CreatedAt: {e.created_at}</code></h5>
                                <p className="text-muted mb-1" dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                {
                                    e.file_attachment !== "" ? e.file_attachment : null
                                }
                                </div>
                                <div className="ms-3">
                                <span style={{color:'white',textTransform:'uppercase'}}  className="badge bg-info text-wrap">
                                    {e.role} 
                                </span>
                                &nbsp;&nbsp;
                                {e.addonrole === "" ? null: <span style={{color:'white',textTransform:'uppercase'}}  className="badge bg-success text-wrap">{e.addonrole}</span> }
                                &nbsp;&nbsp;
                                {
                                    e.user_id == userid ? 
                                <span style={{color:'white',textTransform:'uppercase'}}  className="badge bg-info text-wrap">
                                    <i className="fa fa-edit"></i>
                                </span>:null

                                }
                                
                                &nbsp;&nbsp;
                                {
                                     e.user_id == userid ? <span style={{color:'white',textTransform:'uppercase'}}  className="badge bg-danger text-wrap">
                                     <i className="fa fa-trash"></i>
                                 </span>:null
                                }
                               
                               </div>
                            </div>
                        </div>
               
                      </div>
                        ))
                    }
               
                </CardBody>
            </Card>
                </Col>
              </Row>

              {/* <Row>
                <Col lg="4">
                  <OverviewChart options={options} series={series} />
                </Col>

                {/* <Col lg="4">
                  <AttachedFiles files={projectDetail.files} />
                </Col>

               
              </Row> */}
            </>
             <Modal
                      size="lg"
                      isOpen={modal_large}
                      toggle={() => {
                        tog_large();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myLargeModalLabel"
                        >
                         Add Remarks
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_large(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Form
                        onSubmit={(e)=>{
                            e.preventDefault();
                            let local = JSON.parse(localStorage.getItem('authUser'));

                            let services = [];
                            $('input[name="services"]:checked').each(function() {
                                services.push($(this).val());
                            });
                            let formdata = new FormData();
                            let ar = [];
                            assignedUserData.map((e)=>{
                                ar.push(e.id) 
                            })
                            formdata.append('user_id',local.id);
                            formdata.append('user_role', local.role);
                            formdata.append('description', e.target.elements.description.value);
                            formdata.append('project_lead_id', id);
                            formdata.append('business_name', data.map((e)=>e.business_name));
                            formdata.append('business_assigned_to',ar );
                            formdata.append('send_alert', services.toString());
                            var filesLength=document.getElementById('attached_file').files.length;
                            for(var i=0;i<filesLength;i++){
                                formdata.append("file_attachments[]", document.getElementById('attached_file').files[i]);
                            }
                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", `multipart/form-data boundary:${formdata._boundary}`);
                          
                              axios.post("https://crmapi.frontforcecrm.com/project/add-remarks" ,formdata,{headers:{
                                'Content-Type': `multipart/form-data; boundary=${formdata._boundary}`
                              }
                            }
                                )
                                .then(response => {
                                    if(response.data.message == 'success'){
                                        toastr.success('Project remarks are successfully added')
                                        setmodal_large(!modal_large);
                                        getProjectRemarks(id);
                                    }else{
                                        toastr.error(`${response.data.message}`)
                                    }
                                })
                                
                                .catch(error => console.log('error', error));
                        
                             
                        }}
                        >

                         <Row>
                            <Col md={6}>
                                <label>Username</label>
                                <input name="currentName" type="text" value={userName} className="form-control"/>
                            </Col>
                            <Col md={6}>
                                <label>Role</label>
                                <input name="currentRole" type="text" value={userRole} className="form-control"/>
                            </Col>
                            <hr/>
                            <Col md={12}>
                                <label>Description</label>
                                <textarea name="description" type="text" className="form-control"/>
                            </Col>
                            <hr/>
                            <Col md={12}>
                                <label>Send Alerts</label>
                            <Row>
                          {assignedUserData
                            .map((e, i) => {
                             
                                return (
                                  <Col key={i} xl={3} sm={6}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={e.id}
                                        name="services"
                                        id={`defaultCheck-${e.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`defaultCheck-${e.id}`}
                                      >
                                        {e.name}
                                      </label>
                                    </div>
                                  </Col>
                                )
                              
                            })}
                        </Row>

                            </Col>
                            <hr/>
                            <Col md={12}>
                                <label>File Attachment</label>
                                <input name="file_attached" id="attached_file" multiple type="file" className="form-control"/>
                            </Col>
                            <hr/>
                            <Col md={12} style={{textAlign:'end'}}>
                                 &nbsp;&nbsp;
                                <button className="btn btn-info" type="submit">Submit</button>
                            </Col>
                         </Row>
                        </Form>
                       
                      </div>
                    </Modal>
         
        </Container>
      </div>
    </React.Fragment>
  );
};

ProjectRemarks.propTypes = {
  match: PropTypes.object,
};

export default withRouter(ProjectRemarks);
