import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"

import { Button, Card, CardBody } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import { latestTransaction } from "../../common/data/dashboard"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { UncontrolledTooltip } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"

const ProjectsDataTable = props => {
  const [modal1, setModal1] = useState(false)
  const [data,setData] = useState([]);

  const toggleViewModal = () => setModal1(!modal1)

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
     
      {
        Header: "Project Name",
        accessor: "projectName",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "projectStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "id",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3"
            onClick={()=>deleteProject(cellProps.value)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                 Delete
              </UncontrolledTooltip>
            </div>
          )
        },
      },
    ],
    []
  )
const fetchData=async ()=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch("http://localhost:3030/crm-project", requestOptions)
    .then(response => response.json())
    .then(result => setData(result))
    .catch(error => console.log('error', error));
};
useEffect(()=>{
  fetchData();
  
},[])
const deleteProject = (param)=>{
  var requestOptions = {
    method: 'DELETE',
    redirect: 'follow'
  };
  
  fetch(`http://localhost:3030/crm-project/${param}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.message=='success'){
          toastr.success('Project is successfully deleted');
          fetchData()
         }else{
        toastr.error('Error 500')
      }
    })
    .catch(error => console.log('error', error));
}
  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <TableContainer
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={6}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ProjectsDataTable.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(ProjectsDataTable)
