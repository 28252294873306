import React, { useEffect, useState } from "react"
import ReactEcharts from "echarts-for-react"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"

const Line = ({ dataColors }) => {
  const [seriesdate,setseriesDate ] = useState([])
  const [seriesData,setSeriesData ] = useState([])
  const [count,setCount ] = useState(1)
  const lineEChartColors = getChartColorsArray(dataColors)
  const getValues = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch("http://localhost:3030/dateRangeData", requestOptions)
      .then(response => response.json())
      .then(result => {
        const dt = result.map((e)=>e.date);
        const data = result.map((e)=>e.value);
        setseriesDate(dt)
        setSeriesData(data)
      })
      .catch(error => console.log("error", error))
  }
  useEffect(()=>{
    getValues();
    },[count])
  const options = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
    },
    xAxis: {
      type: "category",
      data: seriesdate,
      axisLine: {
        lineStyle: {
          color: "#8791af",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#8791af",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)",
        },
      },
    },
    series: [
      {
        data: seriesData,
        type: "line",
      },
    ],
    color: lineEChartColors,
    textStyle: {
      color: ["#8791af"],
    },
  }
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  )
}
export default Line
