import React, { useEffect, useState,useRef } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Select from "react-select";
//Import Breadcrumb
import citiesData from '../../../common/data/cities.json'
import countriesData from '../../../common/data/countries.json'
import statesData from '../../../common/data/states.json'

import Breadcrumbs from "../../../components/Common/Breadcrumb"

const AddLead = props => {
  const [selectedGroup,setselectedGroup] = useState(null)
  const [selectedGroupDis,setselectedGroupDis] = useState(null)
  const [selectedGroupCountry,setselectedGroupCountry] = useState(null)
  const [selectedGroupCountryOption,setselectedGroupCountryOption] = useState(null)
  const [selectedGroupState,setselectedGroupState] = useState(null)
  const [selectedGroupStateOption,setselectedGroupStateOption] = useState(null)
  const [selectedGroupCity,setselectedGroupCity] = useState(null)
  const [selectedGroupCityOption,setselectedGroupCityOption] = useState(null)
  const [projectOptions,setProjectOptions] = useState(null)
  const [disposition,setDispositions] = useState([])
  const [selectedGroupUser,setselectedGroupUser] = useState(null)
  const [usersData,setUsersData] = useState([])
  const [followUpType,setFollowUpType] = useState(false)
  const [followUpTypeValue,setFollowUpTypeValue] = useState(null)
  const [followUpTypeTransfer,setFollowUpTypeTransfer] = useState(false)
  const [followUpTypeTransferValue,setFollowUpTypeTransferValue] = useState(null)
  const [currentID,setCurrentID] = useState(null)
  const specificDivRef = useRef(null);
  const specificDivRef2 = useRef(null);

   const getValues = () => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("user", "")

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }

    fetch("https://crmapi.frontforcecrm.com/dashboard-graph", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        
          const projectOptions = result.map((row) => ({
            label: row.country,
            value: row.country,
          }));
          setDispositions(projectOptions)

        
      })
      .catch(error => console.log("error", error))
  }
   const getValuesUser = () => {
  
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch("https://crmapi.frontforcecrm.com/usersforassignment?only=user", requestOptions)
      .then(response => response.json())
      .then(result => {
          const s = result.map((row) => ({
            label: row.name,
            value: row.id,
          }));
          setUsersData(s)

        
      })
      .catch(error => console.log("error", error))
  }
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  function handleSelectGroupDis(selectedGroupDis) {
    setselectedGroupDis(selectedGroupDis);
    if(selectedGroupDis.value == 'Follow-Up'){
      setFollowUpType(true);
    }else{
      setFollowUpType(false);
      setFollowUpTypeTransfer(false)
    }
  }
  function onChangeFollowUpType (selectTye) {
    setFollowUpTypeValue(selectTye);
    if(selectTye.value == 'Transfer'){
      setFollowUpTypeTransfer(true)
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      }
  
      fetch("https://crmapi.frontforcecrm.com/usersforassignment?only=admin", requestOptions)
        .then(response => response.json())
        .then(result => {
            const s = result.map((row) => ({
              label: row.name,
              value: row.id,
            }));
            setUsersData(s)
        })
    }else{
      setFollowUpTypeTransfer(false)
    }
  }
  function onChangeFollowUpTypeValue (selectTye) {
    setFollowUpTypeTransferValue(selectTye);
   
  }
  function handleSelectGroupCountry(selectedGroupCountr) {

    setselectedGroupCountry(null);
    setselectedGroupCountry(selectedGroupCountr);
    setselectedGroupState(null)
    const opio = statesData
    .filter((e) => e.country_name === selectedGroupCountr.value)
    .map((e) => ({
      label: e.name,
      value: e.name,
    }));
    setselectedGroupStateOption(opio);
    console.log(selectedGroupCountry)
  }
  function handleSelectGroupState(selectedGroupState) {
    setselectedGroupState(selectedGroupState);
    setselectedGroupCity(null)
    const opio = citiesData
    .filter((e) => e.state_name === selectedGroupState.value)
    .map((e) => ({
      label: e.name,
      value: e.name,
    }));
    setselectedGroupCityOption(opio);
  }
  function handleSelectGroupCity(selectedGroupCity) {
    setselectedGroupCity(selectedGroupCity);
  }
  function handleSelectGroupUser(selectedGroupUser) {
    setselectedGroupUser(selectedGroupUser);
  }
  document.title = "Add Leads | Front Force CRM"
  const getval = async () =>{
    const local = JSON.parse( localStorage.getItem('authUser'))
    if(local=== null){
      window.location.href="/login"
    }else{
      setCurrentID(local.id);
    }
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch("https://crmapi.frontforcecrm.com/getProjectOptions", requestOptions)
      .then(response => response.json())

      .then(result =>{
        
        const projectOptions = result.map((row) => ({
          label: row.projectName,
          value: row.projectName,
        }));
        setProjectOptions(projectOptions)
        console.log(countriesData)
        const CountriesOptions = countriesData.map((e)=>({
          label:e.name,
          value:e.name
        }))
        setselectedGroupCountryOption(CountriesOptions)
       
      })
      .catch(error => console.log('error', error));
  }
  useEffect(()=>{
    getval();
    getValues();
    getValuesUser()
  },[])
  
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="Add Leads" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Lead Information</CardTitle>

                <Form
                onSubmit={(e)=>{
                  e.preventDefault();
                  console.log(e.target.elements.client_phone.value)
                  if(e.target.elements.client_name.value == ''){
                     e.target.elements.client_name.focus()
                     toastr.error('Error', 'Client Name is required')
                  }
                  else if(e.target.elements.client_phone.value == ''){
                    e.target.elements.client_phone.focus()
                    toastr.error('Error', 'Client Phone is required')
                  }
                  else if(e.target.elements.project_name.value == ''){
                     e.target.elements.project_name.focus()
                     toastr.error('Error', 'Project is required')
                  }
                  else if(e.target.elements.lead_status.value == ''){
                     e.target.elements.lead_status.focus()
                     toastr.error('Error', 'Select Lead Status')
                  }else{
                     let s;
                     let k;
                    if (specificDivRef.current) {
                        s = e.target.elements.follow_up_type.value
                    }
                    if(specificDivRef2.current){
                      k = e.target.elements.transfer_to.value
                    }

                    var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                      var urlencoded = new URLSearchParams();
                      urlencoded.append('project_name',e.target.elements.project_name.value)
                      urlencoded.append('cust_email',e.target.elements.customer_email.value)
                      urlencoded.append('cust_name',e.target.elements.client_name.value)
                      urlencoded.append('cust_phone', e.target.elements.client_phone.value)
                      urlencoded.append('altphone',e.target.elements.alt_phone.value)
                      urlencoded.append('city',e.target.elements.city.value)
                      urlencoded.append('country',e.target.elements.country.value)
                      urlencoded.append('disposition',e.target.elements.lead_status.value)
                      //cuurrentUserID
                      urlencoded.append('insert_by',currentID)
                      if(e.target.elements.assigned_to.value !== null){
                        urlencoded.append('status','Assigned')
                      }else{
                        urlencoded.append('status',null)
                      } 
                      urlencoded.append('assigned_to',e.target.elements.assigned_to.value)
                      urlencoded.append('address',e.target.elements.state.value)
                      urlencoded.append('ping_date',e.target.elements.ping_date.value)
                      urlencoded.append('zip_code',e.target.elements.postal_code.value  )
                      urlencoded.append('street_address',e.target.elements.address.value)
                      urlencoded.append('lead_type',s)
                      urlencoded.append('transfer_to',k)

                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                      };

                      fetch("https://crmapi.frontforcecrm.com/leads/createLead", requestOptions)
                        .then(response => response.json())
                        .then(result =>{
                           if(result.status == 'success'){
                                toastr.success('Success','Lead is successfully added to crm');
                                window.location.reload();
                           }else{
                            toastr.error('Error','Lead is not added yet');
                           }

                          })
                        .catch(error => console.log('error', error));
                  }
                }}
                
                >
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Client Name 
                        </Label>
                        <Input
                          type="text"
                          name="client_name"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Full Name"
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">
                          Phone Number
                        </Label>
                        <Input
                          type="text"
                          name="client_phone"
                          className="form-control"
                          id="formrow-email-Input"
                          placeholder="Phone Number"
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                    <div className="mb-3">
                          <Label>Project Name</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup();
                            }}
                            options={projectOptions}
                            className="select2-selection"
                            name="project_name"
                          />
                        </div>
                 
                    </Col>
                    {

                    }
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label>Select Lead Status</Label>
                        <Select
                            value={selectedGroupDis}
                            onChange={(e) => {
                              handleSelectGroupDis(e);
                            }}
                            options={disposition}
                            name="lead_status"
                            className="select2-selection"
                          />
                      </div>
                    </Col>
                    {
                      followUpType == true?
                      <Col md={4}>
                      <div className="mb-3" ref={specificDivRef} id="specificDiv">
                        <Label>Select Follow-Up Type</Label>
                        <Select
                            value={followUpTypeValue}
                            name="follow_up_type"
                            onChange={(e) => {
                              onChangeFollowUpType(e);
                            }}
                            options={[
                              {
                                label:'Ordinary',
                                value:'Ordinary',
                              },
                              {
                                label:'Transfer',
                                value:'Transfer',
                              }
                            ]}
                            className="select2-selection"
                          />
                      </div>
                    </Col>:null
                    }
                    {
                      followUpTypeTransfer == true?
                      <Col md={4}>
                      <div className="mb-3" ref={specificDivRef2} id="specificDiv2">
                        <Label>Transfer To</Label>
                        <Select
                            value={followUpTypeTransferValue}
                            name="transfer_to"
                            onChange={(e) => {
                              onChangeFollowUpTypeValue(e);
                            }}
                            options={usersData}
                            className="select2-selection"
                          />
                      </div>
                    </Col>:null
                    }
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="example-date-input">Ping Date</Label>
                        <Input
                          className="form-control"
                          type="date"
                          name="ping_date"
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label>Assign To</Label>
                          <Select
                            value={selectedGroupUser}
                            onChange={(e) => {
                              handleSelectGroupUser(e);
                            }}
                            name="assigned_to"
                            options={usersData}
                            className="select2-selection"
                          />
                      </div>
                    </Col>
                  </Row>
                  <CardTitle className="mb-4">Contact Information</CardTitle>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="example-email-input">
                          Email Address
                        </Label>
                        <Input
                          type="email"
                          name="customer_email"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="example-tel-input">
                          Alternate Phone Number
                        </Label>
                        <Input
                          type="tel"
                      
                          name="alt_phone"
                          className="form-control"
                          placeholder="Alternate Phone Number"
                        />
                      </div>
                    </Col>
                  </Row>
                  <CardTitle className="mb-4">Address Information</CardTitle>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="example-address-input">
                          Street Address
                        </Label>
                        <Input
                          type="text"
                          name="address"
                          className="form-control"
                          placeholder="Street Address"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label htmlFor="example-address-input">
                          Select Country
                        </Label>
                        <Select
                            value={selectedGroupCountry}
                            name="country"
                            onChange={(e) => {
                              handleSelectGroupCountry(e);
                            }}
                            options={selectedGroupCountryOption}
                            className="select2-selection"
                          />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-state-Input">
                          Select State
                        </Label>
                        <Select
                            value={selectedGroupState}
                            name="state"
                            onChange={(e) => {
                              handleSelectGroupState(e);
                            }}
                            options={selectedGroupStateOption}
                            className="select2-selection"
                          />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-city-Input">Select City</Label>
                        <Select
                            value={selectedGroupCity}
                            name="city"
                            onChange={(e) => {
                              handleSelectGroupCity(e);
                            }}
                            options={selectedGroupCityOption}
                            className="select2-selection"
                          />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label>Postal Code</Label>
                        <Input
                          type="text"
                          name="postal_code"
                          className="form-control"
                          placeholder="Postal Code"
                        />
                      </div>
                    </Col>
                  </Row>
                  <CardTitle className="mb-4">Comments</CardTitle>
                  <Col md={12}>
                    <div className="mb-3">
                      <Input type="textarea" name="comments" className="textarea" />
                    </div>
                  </Col>
                  <div>
                    <button type="submit" className="btn btn-primary w-md">
                      Submit
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default AddLead
