import React from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"

import DispositionDataTable from "../../../pages/Dashboard/DispositionDataTable"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"

const Dispositions = props => {
  document.title = "Disposition  | Front Force CRM"
  
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Setting" breadcrumbItem="Disposition" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xl={6}>
                    <CardTitle className="mb-4">Disposition</CardTitle>
                  </Col>
                  <Col xl={6} className="text-end">
                    <Link to={"/adddisposition"}>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded mb-2 me-2"
                      >
                        Create
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <DispositionDataTable />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default Dispositions
