import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Col, Container, Row,  UncontrolledTooltip ,Card,CardBody,CardFooter} from "reactstrap";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Card
import CardContact from "pages/Contacts/card-contact";


//redux
import { useSelector, useDispatch } from "react-redux";
import { getUsers as onGetUsers } from "../../../store/contacts/actions";

const ContactsGrid = () => {

  //meta title
  document.title = "Online Users | Front Force CRM";

  const [user,setUser] = useState([])
const fetchdata = async() =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      await fetch("https://crmapi.frontforcecrm.com/get-online", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            setUser(result)
        })
        .catch(error => console.log('error', error));
}
  useEffect(() => {
        fetchdata();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Activity" breadcrumbItem="Online Users" />

          <Row>
         {
             user.map((e,i)=>
             <Col xl="3" sm="6" key={i}>
                    <Card key={i} className="text-center">
                    <CardBody>
                     
                        <div className="mb-4">
                          <img
                            className="rounded-circle avatar-sm"
                            src={`https://uss.frontforcecrm.com/uploads/${e.user_image}`}
                            alt=""
                          />
                        </div>
          
                      <h5 className="font-size-15 mb-1">
                        <Link to="#" className="text-dark">
                          {e.user_name}
                        </Link>
                      </h5>
                      <p className="text-muted">{e.role}</p>
          
                    </CardBody>
                      
                      </Card>
        </Col>
             )
         }
          </Row>

       
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsGrid);
