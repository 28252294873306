import React, { useState } from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { series } from "common/data/projects"

const SearchProject = props => {
  const [search, setSearch] = useState(false)
  const handleAdvanceSearch = () => {
    setSearch(!search)
  }
  document.title = "All Sales    | Front Force CRM"
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Leads Management"
            breadcrumbItem="Search Project"
          />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Search Project</CardTitle>

                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          First Date
                        </Label>
                        <Input
                          className="form-control"
                          type="date"
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-date-Input">Last Date</Label>
                        <Input
                          className="form-control"
                          type="date"
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label>
                          Project Status{""}
                          <i className="mdi mdi-chevron-down"></i>
                        </Label>
                        <select className="form-control">
                          <option>All</option>
                          <option>New</option>
                          <option>Active</option>
                          <option>Pending</option>
                          <option>Completed</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={2} sm={6}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck1"
                          onChange={handleAdvanceSearch}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Advance Search
                        </label>
                      </div>
                    </Col>
                  </Row>
                  {search && (
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>
                            Business Type{""}
                            <i className="mdi mdi-chevron-down"></i>
                          </Label>
                          <select className="form-control">
                            <option>One Time</option>
                            <option>Recurring</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>
                            Select Client{""}
                            <i className="mdi mdi-chevron-down"></i>
                          </Label>
                          <select className="form-control">
                            <option>All</option>
                            <option>New</option>
                            <option>Active</option>
                            <option>Pending</option>
                            <option>Completed</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Business Name
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Business Name"
                            id="example-date-input"
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xl={10} sm={6}>
                      {" "}
                      {/* Empty column */}
                    </Col>
                    <Col xl={2} sm={6}>
                      <div>
                        <button type="submit" className="btn btn-primary w-md">
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Sales</CardTitle>
                <Col lg="12">
                  <div className="table-responsive">
                    <Table className="table mb-0" style={{ marginTop: "50px" }}>
                      <thead className="table-dark">
                        <tr>
                          <th>Client Name</th>
                          <th>Business Name</th>
                          <th>Client Phone</th>
                          <th>Sale Amount</th>
                          <th>Assigned To</th>
                          <th>Last Modified</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Mike e</th>
                          <td>Data Solution</td>
                          <td>6237823197</td>
                          <td>300$</td>
                          <td>Wilsom</td>
                          <td>12/02/2023</td>
                          <td>
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                              style={{ color: "black" }}
                            />
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              style={{ color: "red" }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default SearchProject
