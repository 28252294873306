import React from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Uploadlead = props => {
  document.title = "Upload leads | Front Force CRM"
  return (
    <>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="upload Leads" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Upload Lead <code>Only .xlsx will upload</code>
                </CardTitle>
                <Form>
                  <Row>
                    <Col sm={6}>
                      <div className="mt-3">
                        <Input
                          className="form-control"
                          type="file"
                          id="formFile"
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
                <Card style={{ marginTop: "50px" }}>
                  <CardBody>
                    <CardTitle>
                      Duplicate Data <code>shown here</code>
                    </CardTitle>
                    <Col lg="12">
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead className="table-dark">
                            <tr>
                              <th>Id</th>
                              <th>Project Name</th>
                              <th>Full Name</th>
                              <th>Phone no</th>
                              <th>City</th>
                              <th>Assign To</th>
                              <th>Disposition</th>
                              <th>Ping</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                          <th scope="row">1</th>
                          <td>Mark</td>
                          <td>Otto</td>
                          <td>6237823197</td>
                          <td>Arif</td>
                          <td>12/02/2023</td>
                          <td>
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                              style={{ color: "black" }}
                            />
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              style={{ color: "red" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <Container
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                              style={{
                                backgroundColor: "#556EE6",
                                color: "white",
                              }}
                            >
                              Add new
                            </button>
                          </Container>
                        </tr> */}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default Uploadlead
