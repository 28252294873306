import { React, useState } from "react"

import {
  Collapse,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const GenerateTicket = props => {
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
  }
  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
  }
  document.title = "Add  Ticket | Front Force CRM"
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="Add Ticket" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">FAQs</CardTitle>
                <Col lg={12}>
                  <div className="accordion" id="accordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !col1 }
                          )}
                          type="button"
                          onClick={t_col1}
                          style={{ cursor: "pointer" }}
                        >
                          1 - How can i use CRM?
                        </button>
                      </h2>

                      <Collapse isOpen={col1} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">
                              This is the first item&apos;s accordion body.
                            </strong>{" "}
                            It is hidden by default, until the collapse plugin
                            adds the appropriate classes that we use to style
                            each element. These classes control the overall
                            appearance, as well as the showing and hiding via
                            CSS transitions. You can modify any of this with
                            custom CSS or overriding our default variables.
                            It&apos;s also worth noting that just about any HTML
                            can go within the <code>.accordion-body</code>,
                            though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !col2 }
                          )}
                          type="button"
                          onClick={t_col2}
                          style={{ cursor: "pointer" }}
                        >
                          2 - How i can add lead the CRM
                        </button>
                      </h2>

                      <Collapse isOpen={col2} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">
                              This is the second item&apos;s accordion body.
                            </strong>{" "}
                            It is hidden by default, until the collapse plugin
                            adds the appropriate classes that we use to style
                            each element. These classes control the overall
                            appearance, as well as the showing and hiding via
                            CSS transitions. You can modify any of this with
                            custom CSS or overriding our default variables.
                            It&apos;s also worth noting that just about any HTML
                            can go within the <code>.accordion-body</code>,
                            though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !col3 }
                          )}
                          type="button"
                          onClick={t_col3}
                          style={{ cursor: "pointer" }}
                        >
                          3 - Did user assign the leads to user?
                        </button>
                      </h2>
                      <Collapse isOpen={col3} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <strong className="text-dark">
                              This is the third item&apos;s accordion body.
                            </strong>{" "}
                            It is hidden by default, until the collapse plugin
                            adds the appropriate classes that we use to style
                            each element. These classes control the overall
                            appearance, as well as the showing and hiding via
                            CSS transitions. You can modify any of this with
                            custom CSS or overriding our default variables.
                            It&apos;s also worth noting that just about any HTML
                            can go within the <code>.accordion-body</code>,
                            though the transition does limit overflow.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Generate Ticket</CardTitle>
                <Form>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label>
                          Ticket Pirority{""}
                          <i className="mdi mdi-chevron-down"></i>
                        </Label>
                        <select className="form-control">
                          <option>Select Pirority</option>
                          <option>Low Pirority</option>
                          <option>High Pirority</option>
                          <option>Urgent</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Ticket Pirority
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Ticket Titel"
                        />
                      </div>
                    </Col>
                    {/* <CardTitle className="mb-4">Comments</CardTitle> */}
                    <Col md={12}>
                      <div className="mb-3">
                        <Label>Message</Label>
                        <Input
                          type="textarea"
                          className="textarea"
                          placeholder="Type your message"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <button type="submit" className="btn btn-primary w-md">
                      Submit Ticket
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default GenerateTicket
