import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Tags, Projects } from "./ListCol";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const AllUsers = props => {

  //meta title
  document.title = "Users | Front Force CRM";
  const [userList, setUserList] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Img",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.image ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.name.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={`https://uss.frontforcecrm.com/uploads/${cellProps.image}`}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Role",
        accessor: "role",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
     
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={{pathname:`./editUser/${cellProps.value}`}}
                className="text-success"
                
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  
                  onClickDelete(cellProps.value);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );
  const onClickDelete = (param) =>{
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };
fetch(`https://crmapi.frontforcecrm.com/users/${param}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.message == 'success'){
      toastr.success('User is successfully delete');
      fetchData();
    }else{
      toastr.error(`${result.message}`);
    }
  })
  .catch(error => console.log('error', error));
  }
  const fetchData = async ()=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
fetch("https://crmapi.frontforcecrm.com/users", requestOptions)
  .then(response => response.json())
  .then(result => setUserList(result))
  .catch(error => console.log('error', error));
  }
  useEffect(() => {
  fetchData();
  },[]);

  

  return (
    <React.Fragment>
    
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Users" breadcrumbItem="Users List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={userList}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AllUsers);
