import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"
import { useParams } from "react-router-dom"

const EditUser = () => {
  //meta title
  document.title = "Edit User | Front Force CRM"
  const {id} = useParams();
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [teamLead, setTeamLead] = useState(null)
  const [Role, setRole] = useState(null)
  const [otherrole, setOtherRole] = useState(null)

  const [TechRole, setTechRole] = useState(null)
  const [showTechSection, setShowTechSection] = useState(false)

  const options = [
    { value: "admin", label: "Admin" },
    { value: "supervisior", label: "Supervisior" },
    { value: "user", label: "User" },
    { value: "tech", label: "Tech" },
  ]
  const option = [
    { value: "software developer", label: "Software Developer" },
    { value: "web developer", label: "Web Developer" },
    { value: "mobile developer", label: "Mobile Developer" },
    { value: "designer", label: "Designer" },
    { value: "content writer", label: "Content Writer" },
    { value: "seo", label: "SEO" },
    { value: "smm", label: "SMM" },
    { value: "sqa", label: "SQA" },
    { value: "customer support", label: "Customer Support" },
  ]
  
 

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
const handleOnchange =(e)=>{
    setRole({label:e,value:e})
  if(e == 'tech'){
    setShowTechSection(true)
  }else{
    setShowTechSection(false)
  }
}
const getSingleFromDB = async(param)=>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
     await fetch(`https://crmapi.frontforcecrm.com/users/${param}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
                result.rows.map((e)=>{
                    setName(e.name)
                    setEmail(e.email)
                    setPassword(e.password)
                    setRole({label:e.role , value: e.role})
                    if(e.role=='tech'){
                        setShowTechSection(true)
                        setOtherRole({label:e.addonrole,value:e.addonrole})
                        setTeamLead(e.team_lead)
                    }
                })
           
        })
        .catch(error => console.log('error', error));
}
useEffect(()=>{
    getSingleFromDB(id)
},[id])
const handleChange = (e)=>{
    setOtherRole({label:e,value:e})
}
  return (
    <React.Fragment>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Users" breadcrumbItem="Create User" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                  onSubmit={(e)=>{
                    e.preventDefault();
                
                    let attachments,tlead,otherrole;
                    if(e.target.elements.profile_image.value !== null  ){
                      attachments = $("#profile_image")[0].files[0];
   
                   }
                    var formdata = new FormData();
                    let local = JSON.parse(localStorage.getItem('authUser'));
                    console.log(local)

                    formdata.append("name", e.target.elements.fullname.value);
                    formdata.append("update_id", id);
                    formdata.append("user_profile", attachments);
                    formdata.append("email", e.target.elements.email.value);
                    formdata.append("password", e.target.elements.password.value);
                    formdata.append("status", "active");
                    formdata.append("role", e.target.elements.role.value);
                    formdata.append("addedby", local.id)
                    if(showTechSection === true){
                        tlead =  e.target.elements.team_lead.value
                        otherrole= e.target.elements.addonrole.value
                    }else{
                      tlead = null;
                      otherrole = null;
                    }
                    formdata.append("teamlead", tlead);
                    formdata.append("addonrole",otherrole);
                    axios.post("https://crmapi.frontforcecrm.com/project/edit-user", formdata,{headers:{
                      'Content-Type': `multipart/form-data; boundary=${formdata._boundary}`
                    }
                  })
                      .then(result =>{
                        if(result.data.message=='success'){
                          toastr.success('User is successfully updated');
                          getSingleFromDB(id)
                        }else{
                          toastr.error(`${result.data.message}`)
                        }

                      }

                      )
                      .catch(error => console.log('error', error));
                  }}        
                  >
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">Full Name</Label>
                          <Input
                            id="fullname"
                            name="fullname"
                            type="text"
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                            className="form-control"
                            required
                          />
                          
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="email">Email Address</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            className="form-control"
                            required
                          />
                          
                        </div>
                      </Col>

                      <Col sm="6">
                      <div className="mb-3">
                          <Label htmlFor="userpassword">Password</Label>
                          <Input
                            id="userpassword"
                            name="password"
                            type="password"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                            className="form-control"
                            required
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                      <div className="mb-3">
                          <Label className="control-label">Select Role</Label>
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Choose..."
                            name="role"
                            value={Role}
                            onChange={(e)=>handleOnchange(e.value)}
                            title="role"
                            options={options}
                            required
                          />
                        </div>
                      </Col>
                        {
                          showTechSection == true ?
                          <Col sm="12">
                          <Row>
                            <Col md={6}>
                            <div className="mb-3">
                            <Label className="control-label">Select Department</Label>
                            <Select
                              classNamePrefix="select2-selection"
                              placeholder="Choose..."
                              name="addonrole"
                              title="Department"
                              value={otherrole}
                              onChange={(e)=>handleChange(e.value)}
                              options={option}
                              required
                              
                            />
                          </div>
                            </Col>
                            <Col md={6}>
                            <div className="mb-3">
                            <Label className="control-label">Team Lead</Label>
                           <Row style={{paddingTop:10}}>
                           <Col xl={1} sm={6}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="team_lead"
                          id="exampleRadios18"
                          value="Yes"
                          defaultChecked = {teamLead == 'Yes'? true:false }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios18"
                        >
                          Yes
                        </label>
                      </div>
                    </Col>
                    <Col xl={1} sm={6}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="team_lead"
                          id="exampleRadios19"
                          value="No"
                          defaultChecked = {teamLead == 'No'? true:false }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios19"
                          
                        >
                          No
                        </label>
                      </div>
                    </Col>
                           </Row>
                          </div>
                            </Col>
                          </Row>
                      
                        </Col>:null
                        }
                        <Col sm={6} style={{paddingBottom:10}}>
                        <Label htmlFor="profile_image">Profile Image</Label>
                          <Input
                            id="profile_image"
                            name="profile_image"
                            type="file"
                            className="form-control"
                          /> 
                        </Col>
                    </Row>
                    
                    <div className="d-flex flex-wrap gap-3">
                      <Button type="submit" color="primary" className="btn ">
                        Submit
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditUser
