import React,{useEffect, useMemo, useState} from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../../src/pages/Dashboard/LeadsCol"
//Import Breadcrumb
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Link } from "react-router-dom"

const KPI = props => {
  const [datatorepresent ,setDatafor] = useState([])
  const [disData ,setDisData] = useState([])
  const [userlist ,setUserList] = useState([])

  const columns = useMemo(() => {
    const dynamicColumns = [
     
      {
        Header: "Agent Name",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
    ];
  
    // Add dynamic columns from disData
    disData.forEach(e => {
      dynamicColumns.push({
        Header: e,
        accessor: e,
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />;
        },
      });
    });
  
    return dynamicColumns;
  }, [disData]);
  const deleteLead = (id) =>{
    console.log(id)
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };
    fetch(`https://crmapi.frontforcecrm.com/leads/delete/${id}`, requestOptions)
      .then(response => response.json())
      .then((result) => {
        if(result.message == "Lead deleted successfully"){
            toastr.success('Success','Your lead is delete successfully')
            getValue();
        }else{
            toastr.error('Error',`${result.message}`)
        }
      })
      .catch(error => console.log('error', error));
  }
const getValue = ()=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch("https://crmapi.frontforcecrm.com/leads/get-kpi", requestOptions)
    .then(response => response.json())
    .then(result => {
        console.log(result)
        // setDatafor(result.disposition)
        const results = result.disposition.reduce((acc, item) => {
            const { id, name, disposition, value } = item;
          
            // Check if the user with the given id already exists in the accumulator
            const userExists = acc.some((user) => user.id === id);
          
            if (userExists) {
              // Find the user in the accumulator and update their data
              const existingUser = acc.find((user) => user.id === id);
              existingUser[disposition] = value;
            } else {
              // Add a new user to the accumulator
              const newUser = {
                id,
                name,
                [disposition]: value,
              };
              acc.push(newUser);
            }
          
            return acc;
          }, []);
          setDatafor(results)
        setDisData(result.dispositionArray)
    })
    .catch(error => console.log('error', error));
}
  document.title = "KPI Track | Front Force CRM"
  useEffect(()=>{
    getValue();
  },[])
  return (
    <>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid={true}>
          <Breadcrumbs title="Performance" breadcrumbItem="KPI Track" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">KPI Track</CardTitle>

              
                <Col lg="12">
                 
                  <TableContainer
                    columns={columns}
                    data={datatorepresent}
                    isGlobalFilter={true}
                    customPageSize={30}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default KPI
