import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"

import { Button, Card, CardBody } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import { latestTransaction } from "../../common/data/dashboard"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol"

import { UncontrolledTooltip } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"

const DispositionDataTable = props => {
  const [data, setData] = useState([])

  const columns = useMemo(
    () => [
   
      {
        Header: "Dispositions",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Color",
        accessor: "backgroundColor",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "id",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3"
            onClick={()=>deleteDispositionFromDB(cellProps.value)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </div>
          )
        },
      },
    ],
    []
  )
useEffect(()=>{
  getDataFromDB();
},[])
const getDataFromDB = async ()=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch("http://localhost:3030/dispositions", requestOptions)
    .then(response => response.json())
    .then(result => setData(result))
    .catch(error => console.log('error', error));
}
const deleteDispositionFromDB = async (param) =>{
  var requestOptions = {
    method: 'DELETE',
    redirect: 'follow'
  };
  
  fetch(`http://localhost:3030/dispositions/${param}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.message == 'success'){
        toastr.success('Project is delete successfully')
        getDataFromDB();
      }else{
        toastr.error('Error 500 ')
      }
    })
    .catch(error => console.log('error', error));
}
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <Card>
        <CardBody>
          <TableContainer
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={6}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

DispositionDataTable.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(DispositionDataTable)
