import PropTypes from "prop-types"
import Apaexlinecolumn from "../AllCharts/apex/apaexlinecolumn"
import Line from "../AllCharts/echart/linechart"

import React, { useEffect, useState, useMemo } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { getChartsData as onGetChartsData } from "../../store/actions"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import BarChart from "pages/AllCharts/chartjs/barchart"
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LeadsCol"
import { latestTransaction } from "common/data"
import TableContainer from "components/Common/TableContainer"
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal"
const Dashboard = props => {
  const [count,setCount] = useState(0)
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const toggleViewModal = () => setModal1(!modal1)
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))
  const [datatorepresent, setdatatorepresent] = useState([])

const getData = () =>{

var requestOptions = {
  method: 'GET',
  redirect: 'follow'
};

fetch("https://crmapi.frontforcecrm.com/todayFollowUps?role=admin", requestOptions)
  .then(response => response.json())
  .then(result => {
    setdatatorepresent(result)
  })
  .catch(error => console.log('error', error));
}

  const columns = useMemo(
    () => [
      
      {
        Header: "Business Name",
        accessor: "project_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Client Name",
        accessor: "cust_name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Phone no",
        accessor: "cust_phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },

      {
        Header: "Assigned To",
        disableFilters: true,
        accessor: "name",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Status",
        disableFilters: true,
        accessor: "disposition",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "id",
        Cell: cellProps => {
          
          return (
            <Link
              to={{pathname:`../ping/${cellProps.value}`}}
              color="info"
              className="btn-sm"
              title={`Ping Now`}
            >
              <i className="fa fa-1x fa-mobile" ></i>
            </Link>
          )
        },
      },
    ],
    []
  )

  const getValuesfromLocalStorage = async () => {
    try {
      let convert = []
      const local = localStorage.getItem("authUser")
      console.log(local)
      convert = JSON.parse(local)
      console.log(convert)
      setCurrentUserID(convert.id)
      setCurrentRole(convert.role)
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      }
      fetch(
        `https://crmapi.frontforcecrm.com/main-dashboard?role=${convert.role}&id=${convert.id}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          setTotalLead(result.meeting)
          setTotalFollowUps(result.follow)
          setMissFollowUps(result.misFollow)
          setTodayFollowUps(result.newlead)
          setNextDayFollowUps(result.nextFollow)
          setHotClients(result.hotclient)
          setTotalProjects(result.sales)
        })
        .catch(error => console.log("error", error))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getValuesfromLocalStorage()
    getData();
    setPeriodData(chartsData)
    
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [count,chartsData])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")
  const [CurrentUserID, setCurrentUserID] = useState("")
  const [CurrentRole, setCurrentRole] = useState("")
  const [totalLead, setTotalLead] = useState(0)
  const [totalFollowUps, setTotalFollowUps] = useState(0)
  const [totalProjects, setTotalProjects] = useState(0)
  const [hotClients, setHotClients] = useState(0)
  const [missFollowUps, setMissFollowUps] = useState(0)
  const [todayFollowUps, setTodayFollowUps] = useState(0)
  const [nextDayFollowUps, setNextDayFollowUps] = useState(0)


  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
   
  }, [dispatch])

  //meta title
  document.title = "Fornt Force CRM"
  const reports = [
    {
      title: "Total Leads",
      iconClass: "bx-support",
      description: totalLead,
      link:'/alllead'
    },
    {
      title: "Follow Ups",
      iconClass: "bx-phone-call",
      description: totalFollowUps,
      link:'/disposition/Follow-Up'
    },
    {
      title: "Projects",
      iconClass: "bxs-briefcase",
      description: totalProjects,
      link:'/allprojects'
    },
    {
      title: "Hot Clients",
      iconClass: "bxs-user-plus",
      description: hotClients,
      link:'/disposition/Hot Client'
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            {/* <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning />
            </Col> */}
            <Col xl="12">
              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card
                      className="mini-stats-wid"
                      style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
                    >
                      <a href={report.link}>

                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                      </a>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col lg={4}>
                  <Card color="primary" className="text-white-50">
                    <a href="./follow-up/Missed">

                    <CardBody>
                      <CardTitle className="mb-4 text-white">
                        <i className="mdi mdi-alert-circle-outline me-3" />{" "}
                        Missed Follow-ups({missFollowUps ? missFollowUps : 0})
                      </CardTitle>
                    </CardBody>
                    </a>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card color="dark" className="text-white-50">
                  <a href="./follow-up/Today">
                    <CardBody>
                      <CardTitle className="mb-4 text-white">
                        <i className="mdi mdi-alert-circle-outline me-3" />{" "}
                        Today Follow-ups({todayFollowUps ? todayFollowUps : 0})
                      </CardTitle>
                    </CardBody>
                    </a>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card color="success" className="text-white-50">
                   <a href="./follow-up/Next Day">
                    <CardBody>
                      <CardTitle className="mb-4 text-white">
                        <i className="mdi mdi-alert-circle-outline me-3" />
                        Next Day Follow-ups(
                        {nextDayFollowUps ? nextDayFollowUps : 0})
                      </CardTitle>
                    </CardBody>
                    </a>
                  </Card>
                </Col>
              </Row>
              <Card
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  padding: 30,
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
                }}
              >
                <h3>Today Events</h3>
                <p>No event to display</p>
              </Card>
              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle>Daily Transfers</CardTitle>
                      <div id="line-chart" className="e-chart">
                        <Line dataColors='["--bs-success"]' />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Daily Leads Data</CardTitle>
                      <BarChart dataColors='["--bs-success"]' />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Email Sent</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly")
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly")
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly")
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <StackedColumnChart
                    periodData={periodData}
                    dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
                  />
                </CardBody>
              </Card> */}
            </Col>
          </Row>

          {/* <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>

            <Col xl="4">
              <TopCities />
            </Col>
          </Row> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {JSON.stringify()}
                  <TableContainer
                    columns={columns}
                    data={datatorepresent}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={6}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      {/* <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal> */}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
