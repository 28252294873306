import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Editor } from "react-draft-wysiwyg"
import {convertToRaw, EditorState,ContentState} from 'draft-js'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import axios from "axios"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"

const EditSale = props => {
    const {id} = useParams();
  
  const [clientName, setClientName] = useState(null)
  const [clientPhone, setClientPhone] = useState(null)
  const [clientEmail, setClientEmail] = useState(null)
  const [clientAddress, setClientAddress] = useState(null)
  const [clientCity, setClientCity] = useState(null)
  const [clientPostalCode, setClientPostalCode] = useState(null)
  const [clientId, setClientId] = useState(null)
  const [serviceSell, setServiceSell] = useState([])
  const [priceType, setPriceType] = useState(null)
  const [paymentCycle, setPaymentCycle] = useState(null)
  const [recurringAmount, setRecurringAmount] = useState(null)
  const [recurringDate, setRecurringDate] = useState(null)
  const [saleAmount, setSaleAmount] = useState(null)
  const [advanceStatus, setAdvanceStatus] = useState(null)
  const [advanceAmount, setAdvanceAmount] = useState(null)
  const [businessName, setBusinessName] = useState(null)
  const [businessNiche, setBusinessNiche] = useState(null)
  const [attachmentFile, setAttachmentFile] = useState(null)
 
  const [moreamount, setMoreamount] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [selectedGroupUser, setselectedGroupUser] = useState(null)
  const [clientList, setClientList] = useState([])
  const [showRecurringSection, setShowRecurringSection] = useState(false)
  const [businessDescription, setBusinessDescription] = useState(null)
  const [customerDescription, setCustomerDescription] = useState(null)
  const [techDescription, setTechDescription] = useState(null)
  const [currentID, setCurrentId] = useState(null)
  const getSingleSale = async (param) =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`https://crmapi.frontforcecrm.com/project/singleProject?id=${param}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            result.map((e)=>{
                setClientName(e.client_name)
                setClientPhone(e.client_phone)
                setClientEmail(e.client_email)
                setClientAddress(e.client_address)
                setClientCity(e.client_city)
                setClientPostalCode(e.zip_code)
                setServiceSell(e.service_sell)
                setPaymentCycle(e.business_type)
                setSaleAmount(e.sale_amount)
                setClientId(e.client_id)
                setBusinessName(e.business_name)
                setBusinessNiche(e.business_niche)
                setAdvanceAmount(e.advance_amount_paid)
                setBusinessDescription(e.business_description)
                setCustomerDescription(e.description_for_support)
                setTechDescription(e.description_for_tech)
                if(e.advance_amount_paid){
                    setMoreamount(true)
                }
                if(e.business_type == 'Recurring'){
                    setShowRecurringSection(true)
                }
                if(e.recurring_amount !=='null'){
                    
                    setRecurringAmount(e.recurring_amount);
                }
                if(e.business_end_date !=='null'){
                    setRecurringDate(e.business_end_date);
                }
                if(e.service_sell !==null){
                    const selectedArray = e.service_sell.split(',');
                    selectedArray.forEach(value => {
                        const checkbox = document.querySelector(`[value="${value.trim()}"]`);
                        if (checkbox) {
                            checkbox.checked = true;
                        }
                    });
                }
                if(e.sale_amount_type !== null){
                    const radioButtons = document.querySelectorAll(`input[name="price_type"]`);
                    const stringValue = e.sale_amount_type;
                    radioButtons.forEach(radioButton => {
                        if (radioButton.value === stringValue) {
                            radioButton.checked = true;
                        }
                    });
                }
                if(e.business_attachments !== null){
                    setAttachment(true)
                    setAttachmentFile(e.business_attachments)
                }
            })
        })
        .catch(error => console.log('error', error));
 }
  const handleAdvanceAmount = () => {
    setMoreamount(!moreamount)
  }
  const handleAttachments = () => {
    setAttachment(!attachment)
  }

  useEffect(()=>{
    
    let local = JSON.parse(localStorage.getItem('authUser'));
    if(local == null){
      window.location.href="/login";

    }else{
      setCurrentId(local.id);
      getSingleSale(id)
    }
  },[id])
  document.title = "Edit Sale | Front Force CRM"
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="Add Sale" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Client Information</CardTitle>
               <Form
               onSubmit={(e)=>{
                e.preventDefault();
                let checked = "";
                let client_name;
                let client_phone;
                let client_email;
                let client_addree;
                let client_city;
                let client_postalcode;
                let exiting_client;
                let service_sell = [];
               
                
                $('input[name="service_sell"]:checked').each(function() {
                    service_sell.push($(this).val());
                });
                let sale_amount_type;
                var radioss = document.querySelectorAll('input[name="price_type"]');
                for (var i = 0; i < radioss.length; i++) {
                if(radioss[i].checked ==true){
                  sale_amount_type = (radioss[i].value)
                  }
                }
                if(sale_amount_type == ""){
                    alert('Please select amount type');
                    return false; 
                }
                let payment_cycle =  e.target.elements.payment_cycle.value; 
                let recurring_date=null;
                let recurring_amount=null; 
                if(payment_cycle == 'Recurring'){
                  recurring_amount = e.target.elements.recurring_amount.value;
                  recurring_date = e.target.elements.recurring_date.value;
                }
                let sale_amount = $('#sale_amount').val();
                let status_advance_amount = $('#defaultCheck21')[0].checked;
                let advance_amount = null;
                
                if(status_advance_amount == true){
                    advance_amount = $('#formrow-number-Input').val();
                }
                let business_name = e.target.elements.business_name.value
                let business_niche= e.target.elements.business_niche.value
                let attachments = null;
                let chk = $('#defaultCheck22')[0].checked;
                if(chk == true){
                    if(e.target.elements.business_attachment.value !== null  ){
                      console.log($("#business_attachment")[0])
                       attachments = $("#business_attachment")[0].files[0];
    
                    }else{
                        attachments = attachmentFile
                    }
                }
                  var form = new FormData();
                  
                  form.append('client_name',clientName);
                  form.append('client_email',clientEmail);
                  form.append('client_address',clientAddress);
                  form.append('client_phone',clientPhone);
                  form.append('client_city',clientCity);
                  form.append('client_id',clientId);
                  form.append('project_type',paymentCycle);
                  form.append('business_name',businessName);
                  form.append('business_niche',businessNiche);
                  form.append('business_description',businessDescription);
                  form.append('attached_file',attachments);
                  form.append('service_sold',service_sell.toString());
                  form.append('sale_amount',saleAmount);
                  form.append('advance_amount',advanceAmount);
                  form.append('sale_amount_type',sale_amount_type)
                  form.append('last_recharge_date',recurring_date);
                  form.append('recurring_amount',recurring_amount);
                  form.append('desc_for_support',customerDescription);
                  form.append('desc_for_tech',techDescription);
                  form.append('client_zip_code',clientPostalCode);
                  form.append('currentUserId',currentID);
                  form.append('sale_id',id);
                  var myHeaders = new Headers();
                  
                  myHeaders.append("Content-Type", `multipart/form-data; boundary=${form._boundary}`);
                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: form,
                    redirect: 'follow'
                  };
                  
                  axios.put("https://crmapi.frontforcecrm.com/project/editSale" ,form,{headers:{
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`
                  }
                }
                    )
                    .then(result =>{
                        
                        if(result.data.message === 'success'){
                            toastr.success('Sales data is successfully updated');
                            getSingleSale(id);
                        }else{
                            toastr.error(`${result.data.message}`)
                        }
                    })
                    .catch(error =>{
                        toastr.error(`${error}`)
                    });
               }}
               
               
               >
               
                  <Col xl={12}>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="client_name">
                            Full Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="client_name"
                            placeholder="Full Name"
                            name="client_name"
                            value={clientName}
                            onChange={(e)=>setClientName(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="client_phone">
                            Phone Number
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="client_phone"
                            placeholder="Phone Number"
                            name="client_phone"
                            value={clientPhone}
                            onChange={(e)=>setClientPhone(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="client_email">
                            Email Address
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            id="client_email"
                            name="client_email"
                            value={clientEmail}
                            onChange={(e)=>setClientEmail(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>Address</Label>
                          <Input
                            type="text"
                            placeholder="Address"
                            className="textarea"
                            name="client_addree"
                            id="client_addree"
                            value={clientAddress}
                            onChange={(e)=>setClientAddress(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">City</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="client_city"
                            placeholder="City"
                            name="client_city"
                            value={clientCity}
                            onChange={(e)=>setClientCity(e.target.value)}

                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Postal Code</Label>
                          <Input
                            type="num"
                            className="form-control"
                            placeholder="Postal Code"
                            name="client_postalcode"
                            id="client_postalcode"
                            value={clientPostalCode}
                            onChange={(e)=>setClientPostalCode(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
              
                
                <CardTitle className="mb-4">Sale Information</CardTitle>
                <div>
                  <p>What service are you selling?</p>
                  
                </div>
                <Row>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Website Design and Development"
                        name="service_sell"
                        id="defaultCheck3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck3"
                      >
                        Website Design and Development
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Website Design & Development"
                        name="service_sell"
                        id="defaultCheck4"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck4"
                      >
                        Mobile Application Development
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Full Stack Development Services"
                        name="service_sell"
                        id="defaultCheck5"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck5"
                      >
                        Full Stack Development Services
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Search Engine Optimization"
                        name="service_sell"
                        id="defaultCheck6"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck6"
                      >
                        Search Engine Optimization
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="UI/UX Design And Animation"
                        name="service_sell"
                        id="defaultCheck7"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck7"
                      >
                        UI/UX Design And Animation
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Website Revamping"
                        name="service_sell"
                        id="defaultCheck8"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck8"
                      >
                        Website Revamping
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Website Support and Maintenance"
                        name="service_sell"
                        id="defaultCheck9"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck9"
                      >
                        Website Support and Maintenance
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Social Media Marketing (Paid)"
                        name="service_sell"
                        
                        id="defaultCheck10"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck10"
                      >
                        Social Media Marketing (Paid)
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Local SEO/ Google My Business"
                        name="service_sell"
                        id="defaultCheck11"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck11"
                      >
                        Local SEO/ Google My Business
                      </label>
                    </div>
                  </Col>
                  <Col xl={2} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Pay Per Click (SEM)"
                        name="service_sell"
                        id="defaultCheck12"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck12"
                      >
                        Pay Per Click (SEM)
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Social Media Management"
                        name="service_sell"
                        id="defaultCheck13"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck13"
                      >
                        Social Media Management
                      </label>
                    </div>
                  </Col>
                  <Col xl={2} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Email Marketing"
                        name="service_sell"
                        id="defaultCheck14"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck14"
                      >
                        Email Marketing
                      </label>
                    </div>
                  </Col>
                 
                </Row>
                <Row>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Video Marketing"
                        name="service_sell"
                        id="defaultCheck16"
                        
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck16"
                      >
                        Video Marketing
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="App Store Optimization (ASO)"
                        name="service_sell"
                        id="defaultCheck17"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck17"
                      >
                        App Store Optimization (ASO)
                      </label>
                    </div>
                  </Col>
                </Row>
                <CardBody></CardBody>
                <CardTitle>Amount Information</CardTitle>
                <Row>
                  <div>
                    <p>Select Amount Type</p>
                  </div>
                  <Col xl={1} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="price_type"
                        id="exampleRadios18"
                        value="USD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios18"
                      >
                        USD
                      </label>
                    </div>
                  </Col>
                  <Col xl={1} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="price_type"
                        id="exampleRadios19"
                        value="CAD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios19"
                      >
                        CAD
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="price_type"
                        id="exampleRadios20"
                        value="PKR"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios20"
                      >
                        PKR
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Payment Type</Label>
                      <select className="form-control" name="payment_cycle" onChange={(e)=>{
                        console.log(e.target.value)
                          if(e.target.value === 'One Time'){
                            setShowRecurringSection(false)
                          }else if(e.target.value ==='Recurring'){
                            setShowRecurringSection(true)
                          }
                      }}>
                        <option selected={paymentCycle  == 'One Time'? true : false} value={'One Time'}>One Time</option>
                        <option selected={paymentCycle  == 'Recurring'? true : false} value={'Recurring'}>Recurring</option>
                      </select>
                    </div>
                  </Col>
                  {
                    showRecurringSection === true ? 
                    <Row>
                    <Col md={6}>
                     <div className="mb-3">
                        <Label htmlFor="formrow-num-recurring">
                          Recurring Amount{" "}
                          <code>
                            
                          </code>
                        </Label>
                        <Input
                          type="num"
                          className="form-control"
                          id="formrow-number-recurring"
                          placeholder="0.0"
                          name="recurring_amount"
                          value={recurringAmount}
                          onChange={(e)=>setRecurringAmount(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="formrow-num-Input">
                            Recurring Date
                      
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="formrow-number-r_date"
                          placeholder=""
                          name="recurring_date"
                          value={recurringDate}
                          onChange={(e)=>setRecurringDate(e.target.value)}
                        />
                      </div>
                    </Col>
                    </Row>:null
                  }
                 
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="sale_amount">
                        Sale Amount{" "}
                        <code>
                          Note:Don't add the amount sign. sale amount is not
                          editable
                        </code>
                      </Label>
                      <Input
                        type="num"
                        className="form-control"
                        id="sale_amount"
                        name="sale_amount"
                        placeholder="0.0"
                        value={saleAmount}
                        onChange={(e)=>setSaleAmount(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        name="advance_checker"
                        id="defaultCheck21"
                        onChange={handleAdvanceAmount}

                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck21"
                      >
                        Advance Amount Received?
                      </label>
                    </div>
                  </Col>
                </Row>
                {moreamount && (
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-num-Input">
                          Advance Amount
                        </Label>
                        <Input
                          type="num"
                          className="form-control"
                          id="formrow-number-Input"
                          placeholder="0.00"
                          onChange={(e)=>setAdvanceAmount(e.target.value)}
                          value={advanceAmount}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                <CardBody></CardBody>
                <CardTitle>Business Information</CardTitle>
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Business Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Business Name"
                        name="business_name"
                        value={businessName}
                        onChange={(e)=>setBusinessName(e.target.value)}
                      />
                    </div>
                  </Col>
                
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Select Business Niche</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-I"
                        placeholder="Enter business niche..."
                        name="business_niche"
                        value={businessNiche}
                        onChange={(e)=>setBusinessNiche(e.target.value)}
                        
                      />
                    </div>
                  </Col>
                
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Business Description
                        </CardTitle>
                        
                        <CKEditor
                            editor={ClassicEditor}
                            data={businessDescription}
                            onReady={editor => {
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setBusinessDescription(data)
                            }}
                          />
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Description for Customer Suport
                        </CardTitle>
                        
                        <CKEditor
                            editor={ClassicEditor}
                          data={customerDescription}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setCustomerDescription(data)
                            }}
                          />
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Description for Tech Team
                        </CardTitle>
                        <CKEditor
                            editor={ClassicEditor}
                            data={techDescription}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setTechDescription(data)
                            }}
                          />
                      
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck22"
                        onChange={handleAttachments}
                        // defaultChecked={attachmentFile === null ?  false: true}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck22"
                      >
                        Attachments
                      </label>
                    </div>
                  </Col>
                </Row>
                {attachment && (
                  <Row>
                    {
                        attachmentFile === null ? 
                        null:

                    <Col md={12}>
                        <a href={`https://crmapi.frontforcecrm.com/uploads/${attachmentFile}`} rel={'noreferrer'} target="_blank">File Attached</a>
                    
                    </Col>
                    }
                    <Col sm={6}>
                      <div className="mt-4">
                        <div>
                          <Label htmlFor="business_attachment" className="form-label">
                            Attach a file <code> Only doc,docx,pdf,txt </code>{" "}
                            files types are allowed
                          </Label>
                          <Input
                            className="form-control form-control-sm"
                            id="business_attachment"
                            type="file"
                            name="business_attachment"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xl={10} sm={6}>
                    {" "}
                    {/* Empty column */}
                  </Col>
                  <Col xl={2} sm={6}>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default EditSale
