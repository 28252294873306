import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
  
// actions
import { loginUser } from "../../store/actions"

// import images
import profile from "assets/images/verification-img.png"
import logo from "assets/images/logo.svg"

const Login = props => {
  //meta title
  document.title = "Login | Front Force CRM"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "admin@themesbrand.com" || "",
      password: "123456" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const [err, setErr] = useState("")
  const [message, setMessage] = useState("")

  const handleInputChange = (e, type) => {
    switch (type) {
      case "email":
        setErr("")
        setEmail(e.target.value)
        if (e.target.value === "") {
          setErr("Email has left blank")
        }
        break
      case "pass":
        setErr("")
        setPass(e.target.value)
        if (e.target.value === "") {
          setErr("Password has left blank")
        }
        break
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to CRM.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                        
                        var urlencoded = new URLSearchParams();
                        
                        urlencoded.append("email", email);
                        urlencoded.append("password",pass);
                        
                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };
                        
                        fetch("https://crmapi.frontforcecrm.com/login", requestOptions)
                    
                          .then(response => response.json())
                          .then(result => {
                            if(result.message == 'success'){
                              localStorage.setItem("authUser", JSON.stringify(result));
                              window.location.href= '/dashboard'
                            }
                            else{
                              // put(apiError(result.message))
                              alert('Please')
                            }
                            
                          })
                          .catch(error => console.log('error', error));
                      }}
                    >
                      <p>
                        {err !== "" ? (
                          <span className="error">{err}</span>
                        ) : (
                          <span className="message">{message}</span>
                        )}
                      </p>

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="text"
                          onChange={e => handleInputChange(e, "email")}
                          // onBlur={validation.handleBlur}
                          // value={validation.values.email || ""}
                          // invalid={
                          //   validation.touched.email && validation.errors.email
                          //     ? true
                          //     : false
                          // }
                        />
                        {/* {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null} */}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          // value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={e => handleInputChange(e, "pass")}
                          // onBlur={validation.handleBlur}
                          // invalid={
                          //   validation.touched.password &&
                          //   validation.errors.password
                          //     ? true
                          //     : false
                          // }
                        />
                        {/* {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null} */}
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} CRM Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Unisoftwares
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
