import React from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  CardHeader,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import LineBar from "../../../pages/AllCharts/echart/linebarchart"

import userimage from "../../../assets/images/brands/github.png"

const reports = [
  { title: "All Projects", iconClass: "bx-support", description: "1,235" },
  {
    title: "New",
    iconClass: "bx-phone-call",
    description: "175",
  },
  { title: "Projects Issue", iconClass: "bxs-briefcase", description: "150" },
  {
    title: "Active",
    iconClass: "bxs-user-plus",
    description: "120",
  },
  {
    title: "Closed",
    iconClass: "bxs-user-plus",
    description: "120",
  },
]

const All_Tickets = props => {
  document.title = "All Tickets | Front Force CRM"
  return (
    <>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="All Tickets" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <Row>
                  <CardTitle className="mb-4">All Tickets</CardTitle>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default All_Tickets
