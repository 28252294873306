import React, { useEffect, useState, useRef } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Select from "react-select"
//Import Breadcrumb
import citiesData from "../../../common/data/cities.json"
import countriesData from "../../../common/data/countries.json"
import statesData from "../../../common/data/states.json"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useParams } from "react-router-dom"

const Ping = props => {
  const { id } = useParams()
  const [currentEditId, setCurrentEditID] = useState(id)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedGroupDis, setselectedGroupDis] = useState(null)
  const [selectedGroupCountry, setselectedGroupCountry] = useState(null)
  const [selectedGroupCountryOption, setselectedGroupCountryOption] =
    useState(null)
  const [selectedGroupState, setselectedGroupState] = useState(null)
  const [selectedGroupStateOption, setselectedGroupStateOption] = useState(null)
  const [selectedGroupCity, setselectedGroupCity] = useState(null)
  const [selectedGroupCityOption, setselectedGroupCityOption] = useState(null)
  const [projectOptions, setProjectOptions] = useState(null)
  const [disposition, setDispositions] = useState([])
  const [selectedGroupUser, setselectedGroupUser] = useState(null)
  const [usersData, setUsersData] = useState([])
  const [followUpType, setFollowUpType] = useState(false)
  const [followUpTypeValue, setFollowUpTypeValue] = useState(null)
  const [followUpTypeTransfer, setFollowUpTypeTransfer] = useState(false)
  const [followUpTypeTransferValue, setFollowUpTypeTransferValue] =
    useState(null)
  const [currentID, setCurrentID] = useState(null)
  const specificDivRef = useRef(null)
  const specificDivRef2 = useRef(null)
  const [clientName, setClientName] = useState(null)
  const [clientPhone, setClientPhone] = useState(null)
  const [clientPingDate, setClientPingDate] = useState(null)
  const [clientEmailAddress, setClientEmailAddress] = useState(null)
  const [clientAltNumber, setClientAltNumber] = useState(null)
  const [clientStreetAddress, setClientStreetAddress] = useState(null)
  const [postalCode, setpostalCode] = useState(null)
  const [viewForm, setViewForm] = useState(false)
  const [viewText, setViewText] = useState(true)
  const [ConnectValue, setConnectValue] = useState(null)
  const [infoShared, setInfoShared] = useState(null)
  const [showComments, setShowComments] = useState(false)
  const [commentsValue, setCommentsValue] = useState(null)
  const [commentHistory, setcommentHistory] = useState([])
  const [viewdisposition, setViewDisposition] = useState(false)
  const getValues = () => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")
    var urlencoded = new URLSearchParams()
    urlencoded.append("user", "")
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }
    fetch("https://crmapi.frontforcecrm.com/dashboard-graph", requestOptions)
      .then(response => response.json())
      .then(result => {
        const projectOptions = result.map(row => ({
          label: row.country,
          value: row.country,
        }))
        setDispositions(projectOptions)
      })
      .catch(error => console.log("error", error))
  }
  const getValuesUser = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch("https://crmapi.frontforcecrm.com/usersforassignment?only=user", requestOptions)
      .then(response => response.json())
      .then(result => {
        const s = result.map(row => ({
          label: row.name,
          value: row.id,
        }))
        setUsersData(s)
      })
      .catch(error => console.log("error", error))
  }
  function onchangeConnect(e) {
    setConnectValue(e)
    setShowComments(true)
  }
  function onchangeInfoShared(e) {
    setInfoShared(e)
  }
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }
  function handleSelectGroupDis(selectedGroupDis) {
    setselectedGroupDis(selectedGroupDis)
    if (selectedGroupDis.value == "Follow-Up") {
      setFollowUpType(true)
    } else {
      setFollowUpType(false)
      setFollowUpTypeTransfer(false)
    }
  }
  function onChangeFollowUpType(selectTye) {
    setFollowUpTypeValue(selectTye)
    if (selectTye.value == "Transfer") {
      setFollowUpTypeTransfer(true)
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      }

      fetch(
        "https://crmapi.frontforcecrm.com/usersforassignment?only=admin",
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          const s = result.map(row => ({
            label: row.name,
            value: row.id,
          }))
          setUsersData(s)
        })
    } else {
      setFollowUpTypeTransfer(false)
    }
  }

  function onChangeFollowUpTypeValue(selectTye) {
    setFollowUpTypeTransferValue(selectTye)
  }
  function handleSelectGroupCountry(selectedGroupCountr) {
    setselectedGroupCountry(null)
    setselectedGroupCountry(selectedGroupCountr)
    setselectedGroupState(null)
    const opio = statesData
      .filter(e => e.country_name === selectedGroupCountr.value)
      .map(e => ({
        label: e.name,
        value: e.name,
      }))
    setselectedGroupStateOption(opio)
  }
  function handleSelectGroupState(selectedGroupState) {
    setselectedGroupState(selectedGroupState)
    setselectedGroupCity(null)
    const opio = citiesData
      .filter(e => e.state_name === selectedGroupState.value)
      .map(e => ({
        label: e.name,
        value: e.name,
      }))
    setselectedGroupCityOption(opio)
  }
  function handleSelectGroupCity(selectedGroupCity) {
    setselectedGroupCity(selectedGroupCity)
  }
  function handleSelectGroupUser(selectedGroupUser) {
    setselectedGroupUser(selectedGroupUser)
  }
  document.title = `Ping ${clientName} | Front Force CRM`
  const getval = async () => {
    const local = JSON.parse(localStorage.getItem("authUser"))
    if (local === null) {
      window.location.href = "/login"
    } else {
      setCurrentID(local.id)
    }
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch("https://crmapi.frontforcecrm.com/getProjectOptions", requestOptions)
      .then(response => response.json())

      .then(result => {
        const projectOptions = result.map(row => ({
          label: row.projectName,
          value: row.projectName,
        }))
        setProjectOptions(projectOptions)

        const CountriesOptions = countriesData.map(e => ({
          label: e.name,
          value: e.name,
        }))
        setselectedGroupCountryOption(CountriesOptions)
      })
      .catch(error => console.log("error", error))
  }
  useEffect(() => {
    getval()
    getValues()
    getValuesUser()
    getSingleLead(currentEditId)
  }, [])
  const getSingleLead = id => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch(`https://crmapi.frontforcecrm.com/leads/singlelead?id=${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        result.map(e => {
          setClientName(e.cust_name)
          setClientPhone(e.cust_phone)
          let P_val = {
            label: e.project_name,
            value: e.project_name,
          }
          setselectedGroup(P_val)
          let l_status = {
            label: e.disposition,
            value: e.disposition,
          }
          setselectedGroupDis(l_status)
          if (e.ping_date !== null) {
            let v = e.ping_date.split("T")
            setClientPingDate(v[0])
          }
          if (e.assigned_to !== null) {
            let assign = {
              label: e.name,
              value: e.assigned_to,
            }
            setselectedGroupUser(assign)
          }
          setClientEmailAddress(e.cust_email)
          setClientAltNumber(e.altphone)
          setClientStreetAddress(e.street_address)
          if (e.country) {
            let c_option = {
              label: e.country,
              value: e.country,
            }
            const opio = statesData
              .filter(g => g.country_name === e.country)
              .map(f => ({
                label: f.name,
                value: f.name,
              }))
            setselectedGroupCountry(c_option)
            setselectedGroupStateOption(opio)
            let s = {
              label: e.address,
              value: e.address,
            }
            setselectedGroupState(s)
            const opios = citiesData
              .filter(k => k.state_name === e.address)
              .map(l => ({
                label: l.name,
                value: l.name,
              }))
            setselectedGroupCityOption(opios)
            let ss = {
              label: e.city,
              value: e.city,
            }
            setselectedGroupCity(ss)
            setpostalCode(e.zip_code)
          }
          if (e.lead_type !== "undefined") {
            let type = {
              label: e.lead_type,
              value: e.lead_type,
            }
            setFollowUpType(true)
            setFollowUpTypeValue(type)
          }
          if (e.transfer_to !== "undefined") {
            let types = {
              label: e.transfer_to,
              value: e.transfer_to,
            }
            setFollowUpTypeTransfer(true)
            setFollowUpTypeTransferValue(types)
          }
        })
      })
      .catch(error => console.log("error", error))
  }
  const fetchCommentsHistory = (id)=>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`https://crmapi.frontforcecrm.com/leads/comments/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => setcommentHistory(result))
        .catch(error => console.log('error', error));
  }
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="Edit Leads" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <CardTitle className="mb-4">Lead Information </CardTitle>
                  </Col>
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Button
                      className="mb-4"
                      color="info"
                      onClick={() => {
                        setViewForm(!viewForm)
                        setViewText(!viewText)
                      }}
                    >
                      {viewForm == false ? "Edit Lead" : "View Text"}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      className="mb-4"
                      color="primary"
                      onClick={() => {
                        fetchCommentsHistory(id)
                      }}
                    >
                      Lead History
                    </Button>
                  </Col>
                </Row>

                {viewText === true ? (
                <Form 
                onSubmit={(e)=>{
                    e.preventDefault();
                    if(ConnectValue === null){
                        toastr.error('Please select the call status')
                        return false;
                    }else if(infoShared === null){
                        toastr.error('Please select the info Shared Option');
                        return false;
                    }else if(e.target.elements.next_ping_date.value === null){
                        toastr.error('Please select the next ping date');
                        return false;
                    }else if(commentsValue === null){
                        toastr.error('Comments are required');
                        return false;
                    }else{
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                        const currentDate = new Date();

                        const year = currentDate.getFullYear();
                        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(currentDate.getDate()).padStart(2, '0');

                        const formattedDate = `${year}-${month}-${day}`;
                        var urlencoded = new URLSearchParams();
                        urlencoded.append("leadStatus1", selectedGroupDis.value);
                        urlencoded.append("createdAt1", formattedDate);
                        urlencoded.append("assignTo1", selectedGroupUser.value);
                        urlencoded.append("leadid1", id);
                        urlencoded.append("comments1", commentsValue);
                        urlencoded.append("call_detail1", ConnectValue);
                        urlencoded.append("info_shared1", infoShared);
                        urlencoded.append("user_name", selectedGroupUser.label);
                        urlencoded.append("next_ping_date", e.target.elements.next_ping_date.value );
                        
                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };
                        
                        fetch("https://crmapi.frontforcecrm.com/leads/ping", requestOptions)
                          .then(response => response.json())
                          .then(result => {
                            if(result.message == 'success'){
                                toastr.success('Remarks are successfully added')
                            }else{
                                toastr.error(`${result.message}`)
                            }
                          })
                          .catch(error => console.log('error', error));
                    }
                }}
                >
                      <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={12}>
                          <h6>Client Name</h6>
                          <p>{clientName}</p>
                        </Col>
                        <Col md={12}>
                          <h6>Email Address</h6>
                          <p>{clientEmailAddress}</p>
                        </Col>
                        <Col md={12}>
                          <h6>Phone Number</h6>
                          <p>{clientPhone}</p>
                        </Col>
                        <Col md={12}>
                          <h6>Project Name</h6>
                          <p>
                            {selectedGroup !== null
                              ? selectedGroup.value
                              : "N/a"}
                          </p>
                        </Col>
                        <Col md={12}>
                          <h6>Lead Status</h6>
                          <p>
                            {
                                viewdisposition == false ? 
                                    <div>

                                        {selectedGroupDis !== null ? selectedGroupDis.value : 'N/a'}
                                        
                                        <i onClick={()=>setViewDisposition(!viewdisposition)} style={{fontSize:18}} className="mdi mdi-circle-edit-outline"></i>
                                    </div>
                            
                             : 
                             <div className="mb-3">
                                <Label>Select Lead Status</Label>
                                <Select
                                value={selectedGroupDis}
                                onChange={e => {
                                    handleSelectGroupDis(e)
                                }}
                                options={disposition}
                                name="lead_status"
                                className="select2-selection"
                                />
                            </div>
                             
                                }
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={12}>
                          <h6>Street Address</h6>
                          <p>{clientStreetAddress}</p>
                        </Col>
                        <Col md={12}>
                          <h6>Country</h6>
                          <p>
                            {selectedGroupCountry !== null
                              ? selectedGroupCountry.value
                              : "N/a"}
                          </p>
                        </Col>
                        <Col md={12}>
                          <h6>State</h6>
                          <p>
                            {selectedGroupState !== null
                              ? selectedGroupState.value
                              : "N/a"}
                          </p>
                        </Col>
                        <Col md={12}>
                          <h6>City</h6>
                          <p>
                            {selectedGroupCity !== null
                              ? selectedGroupCity.value
                              : "N/a"}
                          </p>
                        </Col>
                        <Col md={12}>
                          <h6>Postal Code</h6>
                          <p>{postalCode}</p>
                        </Col>
                      </Row>
                      {/* <hr style={{borderWidth:1,borderColor:'black'}}/> */}
                    </Col>
                    
                    <Col md={12} style={{paddingTop:10}}>
                        <h4>Ping Information</h4>
                      <Row style={{paddingTop:10}}>
                        <Col md={4}>
                          <label>Call Status</label>
                          <br></br>
                          <div className="form-check form-radio-outline form-radio-success mb-3">
                            <input
                              type="radio"
                              id="customRadiooutlinecolor1"
                              name="customRadiooutlinecolor1"
                              className="form-check-input"
                              value={"Connected"}
                              //   checked={ConnectValue  === 'Connected'}
                              onChange={e => onchangeConnect(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiooutlinecolor1"
                            >
                              Connected
                            </label>
                          </div>
                          <div className="form-check form-radio-outline form-radio-danger mb-3">
                            <input
                              type="radio"
                              id="customRadiooutlinecolor2"
                              name="customRadiooutlinecolor1"
                              className="form-check-input"
                              value={"Not Connected"}
                              onChange={e => onchangeConnect(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiooutlinecolor2"
                            >
                              Not Connected
                            </label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <label>Info Shared</label>
                          <br></br>
                          <div className="form-check form-radio-outline form-radio-primary mb-3">
                            <input
                              type="radio"
                              id="customRadiooutlinecolor3"
                              name="infoShared"
                              className="form-check-input"
                              value={"email"}
                              onChange={e => onchangeInfoShared(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiooutlinecolor3"
                            >
                              <i className="mdi mdi-email"></i>&nbsp;Email
                            </label>
                          </div>
                          <div className="form-check form-radio-outline form-radio-success mb-3">
                            <input
                              type="radio"
                              id="customRadiooutlinecolor4"
                              name="infoShared"
                              className="form-check-input"
                              value={"whatsapp"}
                              onChange={e => onchangeInfoShared(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiooutlinecolor4"
                            >
                              <i className="mdi mdi-whatsapp"></i>&nbsp;Whatsapp
                            </label>
                          </div>
                        </Col>
                        <Col md={4}>
                            <label>Next Ping Date</label>
                            <input type="date" name="next_ping_date" className="form-control"/>
                        </Col>
                      </Row>
                    </Col>
                    {showComments === true ? (
                      <Col md={12}>
                        <label>Comments</label>
                        <br></br>
                        <textarea
                          className="form-control"
                          value={commentsValue}
                          placeholder="some comments....."
                          onChange={(e)=>setCommentsValue(e.target.value)}
                        ></textarea>
                      </Col>
                    ) : null}
                    <Col md={12} style={{paddingTop:20}}>
                        <Button type="submit"
                        color="primary"
                        >
                            Save Changes
                        </Button>
                    </Col>
                  </Row>
                </Form>
                ) : null}
                {viewForm === true ? (
                  <Form
                    onSubmit={e => {
                      e.preventDefault()

                      if (e.target.elements.client_name.value == "") {
                        e.target.elements.client_name.focus()
                        toastr.error("Error", "Client Name is required")
                      } else if (e.target.elements.client_phone.value == "") {
                        e.target.elements.client_phone.focus()
                        toastr.error("Error", "Client Phone is required")
                      } else if (e.target.elements.project_name.value == "") {
                        e.target.elements.project_name.focus()
                        toastr.error("Error", "Project is required")
                      } else if (e.target.elements.lead_status.value == "") {
                        e.target.elements.lead_status.focus()
                        toastr.error("Error", "Select Lead Status")
                      } else {
                        let s
                        let k
                        if (specificDivRef.current) {
                          s = e.target.elements.follow_up_type.value
                        }
                        if (specificDivRef2.current) {
                          k = e.target.elements.transfer_to.value
                        }

                        var myHeaders = new Headers()
                        myHeaders.append(
                          "Content-Type",
                          "application/x-www-form-urlencoded"
                        )

                        var urlencoded = new URLSearchParams()
                        urlencoded.append(
                          "project_name",
                          e.target.elements.project_name.value
                        )
                        urlencoded.append("cust_email", clientEmailAddress)
                        urlencoded.append("cust_name", clientName)
                        urlencoded.append("cust_phone", clientPhone)
                        urlencoded.append("altphone", clientAltNumber)
                        urlencoded.append("city", e.target.elements.city.value)
                        urlencoded.append(
                          "country",
                          e.target.elements.country.value
                        )
                        urlencoded.append(
                          "disposition",
                          e.target.elements.lead_status.value
                        )

                        //cuurrentUserID
                        urlencoded.append("insert_by", currentID)
                        if (e.target.elements.assigned_to.value !== null) {
                          urlencoded.append("status", "Assigned")
                        } else {
                          urlencoded.append("status", null)
                        }
                        urlencoded.append(
                          "assigned_to",
                          e.target.elements.assigned_to.value
                        )
                        urlencoded.append(
                          "address",
                          e.target.elements.state.value
                        )
                        urlencoded.append(
                          "ping_date",
                          e.target.elements.ping_date.value
                        )
                        urlencoded.append(
                          "zip_code",
                          e.target.elements.postal_code.value
                        )
                        urlencoded.append(
                          "street_address",
                          e.target.elements.address.value
                        )
                        urlencoded.append("lead_type", s)
                        urlencoded.append("transfer_to", k)

                        var requestOptions = {
                          method: "PUT",
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: "follow",
                        }
                        fetch(
                          `https://crmapi.frontforcecrm.com/leads/edit/${id}`,
                          requestOptions
                        )
                          .then(response => response.json())
                          .then(result => {
                            if (result.message == "Lead updated successfully") {
                              toastr.success(
                                "Success",
                                "Lead is successfully updated"
                              )
                              // window.location.reload();
                            } else {
                              toastr.error("Error", "Lead is not added yet")
                            }
                          })
                          .catch(error => console.log("error", error))
                      }
                    }}
                  >
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Client Name
                          </Label>
                          <Input
                            type="text"
                            name="client_name"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Full Name"
                            value={clientName}
                            onChange={e => setClientName(e.value)}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Phone Number
                          </Label>
                          <Input
                            type="text"
                            name="client_phone"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Phone Number"
                            value={clientPhone}
                            onChange={e => setClientPhone(e.value)}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Project Name</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={projectOptions}
                            className="select2-selection"
                            name="project_name"
                          />
                        </div>
                      </Col>
                      {}
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Select Lead Status</Label>
                          <Select
                            value={selectedGroupDis}
                            onChange={e => {
                              handleSelectGroupDis(e)
                            }}
                            options={disposition}
                            name="lead_status"
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      {followUpType == true ? (
                        <Col md={4}>
                          <div
                            className="mb-3"
                            ref={specificDivRef}
                            id="specificDiv"
                          >
                            <Label>Select Follow-Up Type</Label>
                            <Select
                              value={followUpTypeValue}
                              name="follow_up_type"
                              onChange={e => {
                                onChangeFollowUpType(e)
                              }}
                              options={[
                                {
                                  label: "Ordinary",
                                  value: "Ordinary",
                                },
                                {
                                  label: "Transfer",
                                  value: "Transfer",
                                },
                              ]}
                              className="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}
                      {followUpTypeTransfer == true ? (
                        <Col md={4}>
                          <div
                            className="mb-3"
                            ref={specificDivRef2}
                            id="specificDiv2"
                          >
                            <Label>Transfer To</Label>
                            <Select
                              value={followUpTypeTransferValue}
                              name="transfer_to"
                              onChange={e => {
                                onChangeFollowUpTypeValue(e)
                              }}
                              options={usersData}
                              className="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="example-date-input">Ping Date</Label>
                          <Input
                            className="form-control"
                            type="date"
                            name="ping_date"
                            value={clientPingDate}
                            onChange={e => setClientPingDate(e.value)}
                            defaultValue="mm/dd/yyyy"
                            id="example-date-input"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Assign To</Label>
                          <Select
                            value={selectedGroupUser}
                            onChange={e => {
                              handleSelectGroupUser(e)
                            }}
                            name="assigned_to"
                            options={usersData}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    <CardTitle className="mb-4">Contact Information</CardTitle>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="example-email-input">
                            Email Address
                          </Label>
                          <Input
                            type="email"
                            name="customer_email"
                            className="form-control"
                            placeholder="Email Address"
                            value={clientEmailAddress}
                            onChange={e => setClientEmailAddress(e.value)}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="example-tel-input">
                            Alternate Phone Number
                          </Label>
                          <Input
                            type="tel"
                            value={clientAltNumber}
                            onChange={e => setClientAltNumber(e.value)}
                            name="alt_phone"
                            className="form-control"
                            placeholder="Alternate Phone Number"
                          />
                        </div>
                      </Col>
                    </Row>
                    <CardTitle className="mb-4">Address Information</CardTitle>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="example-address-input">
                            Street Address
                          </Label>
                          <Input
                            type="text"
                            name="address"
                            value={clientStreetAddress}
                            onChange={e => setClientStreetAddress(e.value)}
                            className="form-control"
                            placeholder="Street Address"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="example-address-input">
                            Select Country
                          </Label>
                          <Select
                            value={selectedGroupCountry}
                            name="country"
                            onChange={e => {
                              handleSelectGroupCountry(e)
                            }}
                            options={selectedGroupCountryOption}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-state-Input">
                            Select State
                          </Label>
                          <Select
                            value={selectedGroupState}
                            name="state"
                            onChange={e => {
                              handleSelectGroupState(e)
                            }}
                            options={selectedGroupStateOption}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-city-Input">
                            Select City
                          </Label>
                          <Select
                            value={selectedGroupCity}
                            name="city"
                            onChange={e => {
                              handleSelectGroupCity(e)
                            }}
                            options={selectedGroupCityOption}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Postal Code</Label>
                          <Input
                            type="text"
                            name="postal_code"
                            className="form-control"
                            placeholder="Postal Code"
                            value={postalCode}
                            onChange={e => setpostalCode(e.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <CardTitle className="mb-4">Comments</CardTitle>
                      <Col md={12}>
                        <div className="mb-3">
                          <Input type="textarea" name="comments" className="textarea" />
                        </div>
                      </Col> */}
                    <div style={{ paddingTop: 10 }}>
                      <button type="submit" className="btn btn-primary w-md">
                        Save Changes
                      </button>
                    </div>
                  </Form>
                ) : null}
                <Row style={{paddingTop:20}}>
                    <Col style={{background:'#f3f3f3',paddingTop:10}} md={12}><h5>Lead History</h5></Col>
                    <Col md={12}>
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th>Call Date</th>
                                    <th>Info Shared</th>
                                    <th>Next Date</th>
                                    <th>Call By</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            
                                {
                                    commentHistory.length !== 0 ?
                                    <tbody>
                                    {
                                        commentHistory.map((e,i)=>{
                                            let call_date ;
                                            let ping_date ;
                                            if(e.Call_date !==null){
                                                call_date = e.Call_date.split('T')
                                            }
                                            if(e.ping_date !==null){
                                                ping_date = e.ping_date.split('T')
                                            }
                                                return(
                                                    <tr key={i}>
                                                    <td>
                                                        {e.Call_date ? call_date[0] : 'N/a'}
                                                     </td>
                                                    <td>
                                                        {e.infoshared}
                                                     </td>
                                                    <td>
                                                        {e.ping_date ? ping_date[0] : 'N/a'}
                                                     </td>
                                                    <td>
                                                        {e.user_name }
                                                     </td>
                                                    <td>
                                                        {e.comments}
                                                     </td>
                                                     </tr>
                                                );
                                         })
                                      
                                    }    
                                    
                                    </tbody>:null
                                }

                               
                            
                        </table>
                    </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default Ping
