import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Table,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Searchleads = () => {
  //meta title
  document.title = "Search Lead | Front Force CRM"

  const [rows1, setrows1] = useState([{ id: 1 }])

  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }

  function handleRemoveRow(id) {
    if (id !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows)
    }
  }

  const [formRows, setFormRows] = useState([{ id: 1 }])

  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
  }

  const onDeleteFormRow = id => {
    if (id !== 1) {
      var modifiedRows = [...formRows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setFormRows(modifiedRows)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="Search Lead" />

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h6 className="mb-4 card-title">Search Lead</h6>
                  <Form className="repeater" encType="multipart/form-data">
                    <div>
                      {(formRows || []).map((formRow, key) => (
                        <Row key={key}>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Business Phone
                              </Label>
                              <Input
                                className="form-control"
                                type="num"
                                defaultValue=""
                                id="example-id-input"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label>
                                Disposition{""}
                                <i className="mdi mdi-chevron-down"></i>
                              </Label>
                              <select className="form-control">
                                <option>Select Disposition</option>
                                <option>Voice Mail</option>
                                <option>Sale</option>
                                <option>Super Hot Client</option>
                                <option>Bad Lead</option>
                                <option>Wrong Number</option>
                                <option>Long Term</option>
                                <option>Not Interested</option>
                                <option>Hot Client</option>
                                <option>Follow Up</option>
                              </select>
                            </div>
                          </Col>
                          <Col md={2}>
                            <Label>{""}</Label>
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                                style={{ marginTop: 7 }}
                              >
                                Submit
                              </button>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Sales</CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-dark">
                        <tr>
                          <th>Client Name</th>
                          <th>Business Name</th>
                          <th>Client Phone</th>
                          <th>Sale Amount</th>
                          <th>Assigned To</th>
                          <th>Last Modified</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Mike e</th>
                          <td>Data Solution</td>
                          <td>6237823197</td>
                          <td>300$</td>
                          <td>Wilsom</td>
                          <td>12/02/2023</td>
                          <td>
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                              style={{ color: "black" }}
                            />
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              style={{ color: "red" }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Searchleads
