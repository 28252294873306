import React,{useEffect, useMemo, useState} from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Collapse
} from "reactstrap"
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../../src/pages/Dashboard/LeadsCol"
//Import Breadcrumb
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom";
import classnames from "classnames";

const FollowUp = props => {
    const {status} = useParams();
    const [datatorepresent ,setDatafor] = useState([])
    const [userList ,setUserList] = useState([])
    const [col1, setcol1] = useState(false);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
  const columns = useMemo(
    () => [
     
      {
        Header: "Business Name",
        accessor: "project_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Client Name",
        accessor: "cust_name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Phone no",
        accessor: "cust_phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },

      {
        Header: "Assigned To",
        disableFilters: true,
        accessor: "username",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Status",
        disableFilters: true,
        accessor: "disposition",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "id",
        Cell: cellProps => {
          
          return (
            <div>

            <Link
              type=""
              color="primary"
              className="btn-sm"
              title={`Edit`}
              to={{pathname:`/editLead/${cellProps.value}`}}
            >
              <i className="fa fa-1x fa-edit" ></i>
            </Link>
            &nbsp;
            <Link
              type=""
              
              color="danger"
              className="btn-sm"
              title={`Delete`}
              onClick={()=>deleteLead(cellProps.value)}
            >
              <i className="fa fa-1x fa-trash" ></i>
            </Link>
            </div>
          )
        },
      },
      {
        Header: "Task",
        disableFilters: true,
        accessor: "ping_id",
        Cell: cellProps => {
          
          return (
            <Link
              to={{pathname: `/ping/${cellProps.value}`}}
              color="info"
              className="btn-sm"
              title={`Ping Now`}
              // onClick={toggleViewModal}
            >
              <i className="fa fa-1x fa-mobile" ></i>
            </Link>
          )
        },
      },
    ],
    []
  )

const getValue = (param)=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch(`https://crmapi.frontforcecrm.com/leads/follow-Up?status=${param}`, requestOptions)
    .then(response => response.json())
    .then(result => {
        setDatafor(result.data)
        setUserList(result.userList)
    })
    .catch(error => console.log('error', error));
}
  document.title = "All Leads | Front Force CRM"
  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };
  const t_col2 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };
  const t_col3 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };
  useEffect(()=>{
    getValue(status);
  },[status])
  return (
    <>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem={status + ' Follow-Up'} />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{status} Follow-Up</CardTitle>

                <Col lg="12">
                <div className="accordion" id="accordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col1 }
                            )}
                            type="button"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                          >
                            Agent Names with {status} Follow-Up
                          </button>
                        </h2>

                        <Collapse isOpen={col1} className="accordion-collapse">
                          <div className="accordion-body">
                          <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>Agent Name</th>
                                        <th>{status} Follow-Up</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userList.map((e,i)=>{
                                            var randomColor = Math.floor(Math.random()*16777215).toString(16);
                                            console.log(randomColor)
                                            return(<tr key={i}>
                                                <td  ><span style={{backgroundColor:'#'+randomColor,color:'white',padding:10}} >{e.name}</span></td>
                                                <td ><span style={{backgroundColor:'#'+randomColor,color:'white',padding:10}}>{e.value}</span></td>
                                            </tr>);
                                        })
                                    }
                                </tbody>
                            </table>
                          </div>
                        </Collapse>
                      </div>
                    
                    </div>
                    <hr/>

                   
                  <TableContainer
                    columns={columns}
                    data={datatorepresent}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={20}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default FollowUp
