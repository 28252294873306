import React, { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"

const BarChart = ({ dataColors }) => {
  const [count,setCount] = useState(1)
  useEffect(() => {
    getValues()
  },[1])
  const getValues = () => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("user", "")

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }

    fetch("https://crmapi.frontforcecrm.com/dashboard-graph", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        result.map((e)=>{
          const labels = result.map((item) => item.country);
          const data = result.map((item) => item.visits);
          setlabelsArray(labels)
          setDataArray(data)

        })
      })
      .catch(error => console.log("error", error))
  }

  const [labelsArray, setlabelsArray] = useState([])
  const [setData, setDataArray] = useState([])
  var barChartColor = getChartColorsArray(dataColors)
  console.log(labelsArray)
  const data = {
    labels: labelsArray,
    datasets: [
      {
        label: "Dispositions",  
        backgroundColor: barChartColor[0],
        borderColor: barChartColor[0],
        borderWidth: 1,
        hoverBackgroundColor: barChartColor[1],
        hoverBorderColor: barChartColor[1],
        data: setData,
      },
    ],
  }

  const option = {
    scales: {
      dataset: [
        {
          barPercentage: 0.4,
        },
      ],
    },
  }

  return <Bar width={751} height={300} data={data} options={option} />
}
export const BarChartOther = ({ dataColors }) => {
  const [count,setCount] = useState(1)
  const [nameArray,setNamearray] = useState([]);
  const [totalArray, setTotalArray] = useState([]);
  const [activeArray, setActiveArray] = useState([]);
  const [completedArray, setCompletedArray] = useState([]);
  useEffect(() => {
    getValues()
  },[1])
  const getValues = async () => {
    
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      await fetch("https://crmapi.frontforcecrm.com/project-dashboard/tech", requestOptions)
        .then(response => response.json())
        .then(result =>{
          let n = [];
          let t = [];
          let a = [];
          let c = [];
          result.users.forEach((e)=>{
            console.log(e.name)
            n.push(e.name);
            t.push(e.total);
            a.push(e.active);
            c.push(e.complete);
          })
          setNamearray(n)
          setActiveArray(a)
          setTotalArray(t)
          setCompletedArray(c)
        })
        .catch(error => console.log('error', error));
    
  }

  const [labelsArray, setlabelsArray] = useState([])
  const [setData, setDataArray] = useState([])
  var barChartColor = getChartColorsArray(dataColors)
  
  const data = {
    labels: nameArray,
    datasets: [
      {
        label: "Total",  
        backgroundColor: barChartColor[0],
        borderColor: barChartColor[0],
        borderWidth: 1,
        // hoverBackgroundColor: barChartColor[1],
        // hoverBorderColor: barChartColor[1],
        data: totalArray,
      },
      {
        label: "Active",  
        backgroundColor: barChartColor[1],
        borderColor: barChartColor[1],
        borderWidth: 1,
        // hoverBackgroundColor: barChartColor[1],
        // hoverBorderColor: barChartColor[1],
        data: activeArray,
      },
      {
        label: "Complete",  
        backgroundColor: barChartColor[2],
        borderColor: barChartColor[2],
        borderWidth: 1,
        // hoverBackgroundColor: barChartColor[1],
        // hoverBorderColor: barChartColor[1],
        data: completedArray,
      },
    ],
  }

  const option = {
    scales: {
      dataset: [
        {
          barPercentage: 0.4,
        },
      ],
    },
  }

  return <Bar width={751} height={300} data={data} options={option} />
}
export default BarChart
