import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"

import { Editor } from "react-draft-wysiwyg"
import {convertToRaw, EditorState,ContentState} from 'draft-js'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import axios from "axios"

const AddSale = props => {
  const [showForm, setShowForm] = useState(false)
  const [existindClient, setExistindClient] = useState(false)
  const [moreamount, setMoreamount] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [selectedGroupUser, setselectedGroupUser] = useState(null)
  const [clientList, setClientList] = useState([])
  const [showRecurringSection, setShowRecurringSection] = useState(false)
  const [businessDescription, setBusinessDescription] = useState(null)
  const [customerDescription, setCustomerDescription] = useState(null)
  const [techDescription, setTechDescription] = useState(null)
  const [currentID, setCurrentId] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    // const contentRaw = convertToRaw(newEditorState);
    console.log(newEditorState);
  };

  
  function handleSelectGroupUser(selectedGroupUser) {
    setselectedGroupUser(selectedGroupUser);
  }
  const handleCheckboxChanges = (e) => {
    if(e == 'new_client'){
      setShowForm(true)
      setExistindClient(false)
    }else if(e=== 'exiting_client'){
      setExistindClient(true)
      setShowForm(false)
    }
  }

  const handleAdvanceAmount = () => {
    setMoreamount(!moreamount)
  }
  const handleAttachments = () => {
    setAttachment(!attachment)
  }
  const getClients = async() =>{
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

   await fetch("https://crmapi.frontforcecrm.com/project/clients", requestOptions)
      .then(response => response.json())
      .then(result => {
          const s = result.map((row) => ({
            label: row.client_name,
            value: row.id,
          }));
          setClientList(s)
      })
  }
  useEffect(()=>{
    getClients();
    let local = JSON.parse(localStorage.getItem('authUser'));
    if(local == null){
      window.location.href="/login";

    }else{
      setCurrentId(local.id);
    }
  },[])
  document.title = "Add Sale | Front Force CRM"
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="Add Sale" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Client Information</CardTitle>
               <Form
               onSubmit={(e)=>{
                e.preventDefault();
                let checked = "";
                let client_name;
                let client_phone;
                let client_email;
                let client_addree;
                let client_city;
                let client_postalcode;
                let exiting_client;
                let service_sell = [];
                
                var radios = document.querySelectorAll('input[name="check_client"]');
                for (var i = 0; i < radios.length; i++) {
                if(radios[i].checked ==true){
                    checked = (radios[i].value)
                }
                }
                if(checked == ""){
                    alert('Please select client type');
                    return false; 
                }
                if(checked == 'new_client'){
                  client_name  =  $('#client_name').val();
                  client_phone  =  $('#client_phone').val();
                  client_email  =  $('#client_email').val();
                  client_addree  =  $('#client_addree').val();
                  client_city  =  $('#client_city').val();
                  client_postalcode  =  $('#client_postalcode').val();
                  }else if(checked === 'exiting_client'){
                    exiting_client = selectedGroupUser.value;
                  }
                $('input[name="service_sell"]:checked').each(function() {
                    service_sell.push($(this).val());
                });
                let sale_amount_type;
                var radioss = document.querySelectorAll('input[name="price_type"]');
                for (var i = 0; i < radioss.length; i++) {
                if(radioss[i].checked ==true){
                  sale_amount_type = (radioss[i].value)
                  }
                }
                if(sale_amount_type == ""){
                    alert('Please select amount type');
                    return false; 
                }
                let payment_cycle =  e.target.elements.payment_cycle.value; 
                let recurring_date=null;
                let recurring_amount=null; 
                if(payment_cycle == 'Recurring'){
                  recurring_amount = e.target.elements.recurring_amount.value;
                  recurring_date = e.target.elements.recurring_date.value;
                }
                let sale_amount = $('#sale_amount').val();
                let status_advance_amount = $('#defaultCheck21')[0].checked;
                let advance_amount = null;
                
                if(status_advance_amount == true){
                    advance_amount = $('#formrow-number-Input').val();
                }
                let business_name = e.target.elements.business_name.value
                let business_niche= e.target.elements.business_niche.value
                let attachments = null;
                let defaultCheck22 = $('#defaultCheck22')[0].checked;
                if(defaultCheck22 == true){
                  if(e.target.elements.business_attachment.value !== null  ){
                     attachments = $("#business_attachment")[0].files[0];
  
                  }
                }
                 
                  var form = new FormData();
                  
                  form.append('client_type',checked);
                  form.append('client_name',client_name);
                  form.append('client_email',client_email);
                  form.append('client_address',client_addree);
                  form.append('client_phone',client_phone);
                  form.append('client_city',client_city);
                  form.append('exiting_client_id',exiting_client);
                  form.append('project_type',payment_cycle);
                  form.append('business_name',business_name);
                  form.append('business_niche',business_niche);
                  form.append('business_description',businessDescription);
                  form.append('attached_file',attachments);
                  form.append('service_sold',service_sell.toString());
                  form.append('sale_amount',sale_amount);
                  form.append('advance_amount',advance_amount);
                  form.append('sale_amount_type',sale_amount_type)
                  form.append('last_recharge_date',recurring_date);
                  form.append('recurring_amount',recurring_amount);
                  form.append('desc_for_support',customerDescription);
                  form.append('desc_for_tech',techDescription);
                  form.append('client_zip_code',client_postalcode);
                  form.append('currentUserId',currentID);
                  
                  axios.post("https://crmapi.frontforcecrm.com/project/create-sale" ,form,{headers:{
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`
                  }
                }
                    )
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
               }}
               
               
               >
                <Row>
                  <Col xl={2} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="new_client"
                        name="check_client"
                        id="defaultCheck1"
                        
                        onChange={(e)=>handleCheckboxChanges(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        New Client
                      </label>
                    </div>
                  </Col>
                  <Col xl={2} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="check_client"
                        value="exiting_client"
                        id="defaultCheck2"
                        onChange={(e)=>handleCheckboxChanges(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck2"
                      >
                        Existing Client
                      </label>
                    </div>
                  </Col>
                </Row>
                {showForm && (
                  <Col xl={12}>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="client_name">
                            Full Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="client_name"
                            placeholder="Full Name"
                            name="client_name"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="client_phone">
                            Phone Number
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="client_phone"
                            placeholder="Phone Number"
                            name="client_phone"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="client_email">
                            Email Address
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            id="client_email"
                            name="client_email"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>Address</Label>
                          <Input
                            type="text"
                            placeholder="Address"
                            className="textarea"
                            name="client_addree"
                            id="client_addree"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">City</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="client_city"
                            placeholder="City"
                            name="client_city"

                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Postal Code</Label>
                          <Input
                            type="num"
                            className="form-control"
                            placeholder="Postal Code"
                            name="client_postalcode"
                            id="client_postalcode"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                {existindClient && (
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label>
                          Select Client <i className="mdi mdi-chevron-down"></i>
                        </Label>
                        <Select
                            value={selectedGroupUser}
                            onChange={(e) => {
                              handleSelectGroupUser(e);
                            }}
                            options={clientList}
                            name="lead_status"
                            className="select2-selection"
                          />
                      </div>
                    </Col>
                   </Row>
                )}
                
                <CardTitle className="mb-4">Sale Information</CardTitle>
                <div>
                  <p>What service are you selling?</p>
                </div>
                <Row>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Website Design and Development"
                        name="service_sell"
                        id="defaultCheck3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck3"
                      >
                        Website Design and Development
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Website Design & Development"
                        name="service_sell"
                        id="defaultCheck4"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck4"
                      >
                        Mobile Application Development
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Full Stack Development Services"
                        name="service_sell"
                        id="defaultCheck5"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck5"
                      >
                        Full Stack Development Services
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Search Engine Optimization"
                        name="service_sell"
                        id="defaultCheck6"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck6"
                      >
                        Search Engine Optimization
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="UI/UX Design And Animation"
                        name="service_sell"
                        id="defaultCheck7"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck7"
                      >
                        UI/UX Design And Animation
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Website Revamping"
                        name="service_sell"
                        id="defaultCheck8"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck8"
                      >
                        Website Revamping
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Website Support and Maintenance"
                        name="service_sell"
                        id="defaultCheck9"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck9"
                      >
                        Website Support and Maintenance
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Social Media Marketing (Paid)"
                        name="service_sell"
                        
                        id="defaultCheck10"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck10"
                      >
                        Social Media Marketing (Paid)
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Local SEO/ Google My Business"
                        name="service_sell"
                        id="defaultCheck11"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck11"
                      >
                        Local SEO/ Google My Business
                      </label>
                    </div>
                  </Col>
                  <Col xl={2} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Pay Per Click (SEM)"
                        name="service_sell"
                        id="defaultCheck12"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck12"
                      >
                        Pay Per Click (SEM)
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Social Media Management"
                        name="service_sell"
                        id="defaultCheck13"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck13"
                      >
                        Social Media Management
                      </label>
                    </div>
                  </Col>
                  <Col xl={2} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Email Marketing"
                        name="service_sell"
                        id="defaultCheck14"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck14"
                      >
                        Email Marketing
                      </label>
                    </div>
                  </Col>
                 
                </Row>
                <Row>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Video Marketing"
                        name="service_sell"
                        id="defaultCheck16"
                        
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck16"
                      >
                        Video Marketing
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="App Store Optimization (ASO)"
                        name="service_sell"
                        id="defaultCheck17"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck17"
                      >
                        App Store Optimization (ASO)
                      </label>
                    </div>
                  </Col>
                </Row>
                <CardBody></CardBody>
                <CardTitle>Amount Information</CardTitle>
                <Row>
                  <div>
                    <p>Select Amount Type</p>
                  </div>
                  <Col xl={1} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="price_type"
                        id="exampleRadios18"
                        value="USD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios18"
                      >
                        USD
                      </label>
                    </div>
                  </Col>
                  <Col xl={1} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="price_type"
                        id="exampleRadios19"
                        value="CAD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios19"
                      >
                        CAD
                      </label>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="price_type"
                        id="exampleRadios20"
                        value="PKR"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios20"
                      >
                        PKR
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Payment Type</Label>
                      <select className="form-control" name="payment_cycle" onChange={(e)=>{
                        console.log(e.target.value)
                          if(e.target.value === 'One Time'){
                            setShowRecurringSection(false)
                          }else if(e.target.value ==='Recurring'){
                            setShowRecurringSection(true)
                          }
                      }}>
                        <option value={'One Time'}>One Time</option>
                        <option value={'Recurring'}>Recurring</option>
                      </select>
                    </div>
                  </Col>
                  {
                    showRecurringSection === true ? 
                    <Row>
                    <Col md={6}>
                     <div className="mb-3">
                        <Label htmlFor="formrow-num-recurring">
                          Recurring Amount{" "}
                          <code>
                            
                          </code>
                        </Label>
                        <Input
                          type="num"
                          className="form-control"
                          id="formrow-number-recurring"
                          placeholder="0.0"
                          name="recurring_amount"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="formrow-num-Input">
                            Recurring Date
                      
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="formrow-number-r_date"
                          placeholder=""
                          name="recurring_date"
                        />
                      </div>
                    </Col>
                    </Row>:null
                  }
                 
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="sale_amount">
                        Sale Amount{" "}
                        <code>
                          Note:Don't add the amount sign. sale amount is not
                          editable
                        </code>
                      </Label>
                      <Input
                        type="num"
                        className="form-control"
                        id="sale_amount"
                        name="sale_amount"
                        placeholder="0.0"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        name="advance_checker"
                        id="defaultCheck21"
                        onChange={handleAdvanceAmount}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck21"
                      >
                        Advance Amount Received?
                      </label>
                    </div>
                  </Col>
                </Row>
                {moreamount && (
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-num-Input">
                          Advance Amount
                        </Label>
                        <Input
                          type="num"
                          className="form-control"
                          id="formrow-number-Input"
                          placeholder="0.00"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                <CardBody></CardBody>
                <CardTitle>Business Information</CardTitle>
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Business Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Business Name"
                        name="business_name"
                      />
                    </div>
                  </Col>
                
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Select Business Niche</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-I"
                        placeholder="Enter business niche..."
                        name="business_niche"
                      />
                    </div>
                  </Col>
                
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Business Description
                        </CardTitle>
                        
                        <CKEditor
                            editor={ClassicEditor}
                            
                            onReady={editor => {
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setBusinessDescription(data)
                            }}
                          />
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Description for Customer Suport
                        </CardTitle>
                        
                        <CKEditor
                            editor={ClassicEditor}
                          
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setCustomerDescription(data)
                            }}
                          />
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Description for Tech Team
                        </CardTitle>
                        <CKEditor
                            editor={ClassicEditor}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setTechDescription(data)
                            }}
                          />
                      
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} sm={6}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck22"
                        onChange={handleAttachments}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck22"
                      >
                        Attachments
                      </label>
                    </div>
                  </Col>
                </Row>
                {attachment && (
                  <Row>
                    <Col sm={6}>
                      <div className="mt-4">
                        <div>
                          <Label htmlFor="business_attachment" className="form-label">
                            Attach a file <code> Only doc,docx,pdf,txt </code>{" "}
                            files types are allowed
                          </Label>
                          <Input
                            className="form-control form-control-sm"
                            id="business_attachment"
                            type="file"
                            name="business_attachment"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xl={10} sm={6}>
                    {" "}
                    {/* Empty column */}
                  </Col>
                  <Col xl={2} sm={6}>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default AddSale
