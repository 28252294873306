import React,{useEffect, useMemo, useState} from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../../src/pages/Dashboard/LeadsCol"
//Import Breadcrumb
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Select from "react-select"; 
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Link } from "react-router-dom"

const Assignleads = props => {
  const [datatorepresent ,setDatafor] = useState([])
  const [selectedGroup,setselectedGroup] = useState(null)
  const [projectOptions,setProjectOptions] = useState(null)
  const [currentID,setCurrentID] = useState(null)
  const [selectedGroupUser,setselectedGroupUser] = useState(null)
  const [usersData,setUsersData] = useState([])
  const [userrole,setUserRole] = useState(null)
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ping_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Business Name",
        accessor: "project_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Client Name",
        accessor: "cust_name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Phone no",
        accessor: "cust_phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },

     
      {
        Header: "Status",
        disableFilters: true,
        accessor: "disposition",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "id",
        Cell: cellProps => {
          
          return (
            <div>

            <Link
              type=""
              color="primary"
              className="btn-sm"
              title={`Edit`}
              to={{pathname:`/editLead/${cellProps.value}`}}
            >
              <i className="fa fa-1x fa-edit" ></i>
            </Link>
            &nbsp;
            <Link
              type=""
              
              color="danger"
              className="btn-sm"
              title={`Delete`}
              onClick={()=>deleteLead(cellProps.value)}
            >
              <i className="fa fa-1x fa-trash" ></i>
            </Link>
            </div>
          )
        },
      },
   
    ],
    []
  )
  const getValuesUser = () => {
  
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }

    fetch("https://crmapi.frontforcecrm.com/usersforassignment?only=user", requestOptions)
      .then(response => response.json())
      .then(result => {
          const s = result.map((row) => ({
            label: row.name,
            value: row.id,
          }));
          setUsersData(s)

        
      })
      .catch(error => console.log("error", error))
  }
  const getval = async () =>{
    const local = JSON.parse( localStorage.getItem('authUser'))
    if(local=== null){
      window.location.href="/login"
    }else{
      setCurrentID(local.id);
      setUserRole(local.role);
    }
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch("https://crmapi.frontforcecrm.com/getProjectOptions", requestOptions)
      .then(response => response.json())

      .then(result =>{
        const projectOptions = result.map((row) => ({
          label: row.projectName,
          value: row.projectName,
        }));
        console.log(result)
        setProjectOptions(projectOptions)
        
       
      })
      .catch(error => console.log('error', error));
  }
  const deleteLead = (id) =>{
    console.log(id)
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };
    fetch(`https://crmapi.frontforcecrm.com/leads/delete/${id}`, requestOptions)
      .then(response => response.json())
      .then((result) => {
        if(result.message == "Lead deleted successfully"){
            toastr.success('Success','Your lead is delete successfully')
            getValue();
        }else{
            toastr.error('Error',`${result.message}`)
        }
      })
      .catch(error => console.log('error', error));
  }
  function handleSelectGroupUser(selectedGroupUser) {
    setselectedGroupUser(selectedGroupUser);
  }
const getValue = ()=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  fetch("https://crmapi.frontforcecrm.com/leads/unassigned_leads", requestOptions)
    .then(response => response.json())
    .then(result => setDatafor(result))
    .catch(error => console.log('error', error));
}
  document.title = "Assign Leads | Front Force CRM"
  useEffect(()=>{
    getValue();
    getval();
    getValuesUser()
  },[])
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  return (
    <>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid={true}>
          <Breadcrumbs title="Leads Management" breadcrumbItem="UnAssinged Leads" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">UnAssgined Leads</CardTitle>

                <Form
                onSubmit={(e)=>{
                  e.preventDefault();
                  var myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                  var urlencoded = new URLSearchParams();
                  urlencoded.append("count1", e.target.elements.first_limit.value);
                  urlencoded.append("count2", e.target.elements.second_limit.value);
                  urlencoded.append("project", e.target.elements.project_name.value);
                  urlencoded.append("user_id", e.target.elements.assigned_to.value);
                  urlencoded.append("user_name", userrole);

                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: 'follow'
                  };

                  fetch("https://crmapi.frontforcecrm.com/leads/assignLeads", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                      if(result.message == 'Leads are successfully assigned'){
                        toastr.success(`${result.message}`)
                        getValue()
                      }else{
                        toastr.error(`${result.message}`)
                      }
                    })
                    .catch(error => console.log('error', error));
                }}
                
                >
                <Row>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">
                        1st Lead ID
                      </Label>
                      <Input
                        className="form-control"
                        type="num"
                        defaultValue=""
                        name="first_limit"
                        id="example-id-input"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">End Lead ID</Label>
                      <Input
                        className="form-control"
                        type="num"
                        defaultValue=""
                        name="second_limit"
                        id="example-id-input"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Select Project</Label>
                        <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup();
                            }}
                            options={projectOptions}
                            className="select2-selection"
                            name="project_name"
                            required
                          />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Select Agent</Label>
                      <Select
                            value={selectedGroupUser}
                            onChange={(e) => {
                              handleSelectGroupUser(e);
                            }}
                            name="assigned_to"
                            options={usersData}
                            required
                            className="select2-selection"
                         />
                    </div>
                  </Col>
                  <Col md={2}>
                    <Label>{""}</Label>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary w-md"
                        style={{ marginTop: 7 }}
                      >
                        Assign Lead
                      </button>
                    </div>
                  </Col>
                </Row>
                </Form>
                <Col lg="12">
                  <TableContainer
                    columns={columns}
                    data={datatorepresent}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={20}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default Assignleads
