import React,{useEffect, useMemo, useState} from "react"

import {
  Table,
  CardSubtitle,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../../src/pages/Dashboard/LeadsCol"
//Import Breadcrumb
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Link } from "react-router-dom"

const Allsale = props => {
  const [datatorepresent ,setDatafor] = useState([])
  const columns = useMemo(
    () => [
     
      {
        Header: "Business Name",
        accessor: "business_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Client Name",
        accessor: "client_name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Phone no",
        accessor: "client_phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "sale_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },

      {
        Header: "Sale To",
        disableFilters: true,
        accessor: "name",
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Modified Date",
        disableFilters: true,
        accessor: "modified_date",
        Cell: cellProps => {
          
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "id",
        Cell: cellProps => {
          
          return (
            <div>

            <Link
              type=""
              color="primary"
              className="btn-sm"
              title={`Edit`}
              to={{pathname:`/editSale/${cellProps.value}`}}
            >
              <i className="fa fa-1x fa-edit" ></i>
            </Link>
            &nbsp;
            <Link
              type=""
              
              color="danger"
              className="btn-sm"
              title={`Delete`}
              onClick={()=>deleteSale(cellProps.value)}
            >
              <i className="fa fa-1x fa-trash" ></i>
            </Link>
            </div>
          )
        },
      },
     
    ],
    []
  )
  const deleteSale = (id) =>{
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };
    fetch(`https://crmapi.frontforcecrm.com/project/deleteProject/${id}`, requestOptions)
      .then(response => response.json())
      .then((result) => {
        if(result.message == "Sale deleted successfully"){
            toastr.success('Success','Your lead is delete successfully')
            getValue();
        }else{
            toastr.error('Error',`${result.message}`)
        }
      })
      .catch(error => console.log('error', error));
  }
const getValue = ()=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch("https://crmapi.frontforcecrm.com/project", requestOptions)
    .then(response => response.json())
    .then(result => setDatafor(result))
    .catch(error => console.log('error', error));
}
  document.title = "Sales | Front Force CRM"
  useEffect(()=>{
    getValue();
  },[])
  return (
    <>
      <div className="page-content" style={{ minHeight: 600 }}>
        <Container fluid={true}>
          <Breadcrumbs title="Sales Management" breadcrumbItem="Sales" />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Sales</CardTitle>

                {/* <Form>
                  <Row>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          First Date
                        </Label>
                        <Input
                          className="form-control"
                          type="date"
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">Last Date</Label>
                        <Input
                          className="form-control"
                          type="date"
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Select Project</Label>
                        <select className="form-control">
                          <option>Select Project Name</option>
                          <option>Web design and development</option>
                          <option>Local SEO</option>
                          <option>SEO</option>
                          <option>Digital Markiting</option>
                          <option>Social Media Management</option>
                          <option>Application Management</option>
                          <option>Bussiness Process Outsourcing</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={3}>
                      <Label>{""}</Label>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          style={{ marginTop: 7 }}
                        >
                          Filter Data
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form> */}
                <Col lg="12">
                  <TableContainer
                    columns={columns}
                    data={datatorepresent}
                    isGlobalFilter={true}
                    // isAddOptions={true}
                    customPageSize={20}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  )
}
export default Allsale
